/**
 * Colors
 */
/**
 * Breakpoints & Media Queries
 */
/**
 * Often re-used variables
 */
/**
 * Breakpoint mixins
 */
/**
 * Long content fade mixin
 *
 * Creates a fading overlay to signify that the content is longer
 * than the space allows.
 */
/**
 * Button states and focus styles
 */
/**
 * Applies editor left position to the selector passed as argument
 */
/**
 * Applies editor right position to the selector passed as argument
 */
/**
 * Styles that are reused verbatim in a few places
 */
/**
 * Allows users to opt-out of animations via OS-level preferences.
 */
/**
 * Reset default styles for JavaScript UI based pages.
 * This is a WP-admin agnostic reset
 */
/**
 * Reset the WP Admin page styles for Gutenberg-like pages.
 */
@media (min-width: 782px) {
  body.js.is-fullscreen-mode {
    margin-top: -46px;
    height: calc(100% + 46px);
    animation: edit-post__fade-in-animation 0.3s ease-out 0s;
    animation-fill-mode: forwards; } }
  @media (min-width: 782px) and (min-width: 782px) {
    body.js.is-fullscreen-mode {
      margin-top: -32px;
      height: calc(100% + 32px); } }

@media (min-width: 782px) {
    body.js.is-fullscreen-mode #adminmenumain,
    body.js.is-fullscreen-mode #wpadminbar {
      display: none; }
    body.js.is-fullscreen-mode #wpcontent,
    body.js.is-fullscreen-mode #wpfooter {
      margin-left: 0; } }
  @media (min-width: 782px) and (prefers-reduced-motion: reduce) {
    body.js.is-fullscreen-mode {
      animation-duration: 1ms; } }

@media (min-width: 782px) {
    body.js.is-fullscreen-mode .edit-post-header {
      transform: translateY(-100%);
      animation: edit-post-fullscreen-mode__slide-in-animation 0.1s forwards; } }
    @media (min-width: 782px) and (prefers-reduced-motion: reduce) {
      body.js.is-fullscreen-mode .edit-post-header {
        animation-duration: 1ms; } }

@keyframes edit-post-fullscreen-mode__slide-in-animation {
  100% {
    transform: translateY(0%); } }

.edit-post-header {
  padding: 4px 2px;
  border-bottom: 1px solid #e2e4e7;
  background: #fff;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  max-width: 100vw;
  z-index: 30;
  left: 0;
  right: 0; }
  @media (min-width: 280px) {
    .edit-post-header {
      height: 56px;
      top: 0;
      position: -webkit-sticky;
      position: sticky;
      flex-wrap: nowrap; } }
  @media (min-width: 600px) {
    .edit-post-header {
      position: fixed;
      padding: 8px;
      top: 46px; } }
  @media (min-width: 782px) {
    .edit-post-header {
      top: 32px; }
      body.is-fullscreen-mode .edit-post-header {
        top: 0; } }
  .edit-post-header .editor-post-switch-to-draft + .editor-post-preview {
    display: none; }
    @media (min-width: 600px) {
      .edit-post-header .editor-post-switch-to-draft + .editor-post-preview {
        display: inline-flex; } }
  .edit-post-header > .edit-post-header__settings {
    order: 1; }
    @supports ((position: -webkit-sticky) or (position: sticky)) {
      .edit-post-header > .edit-post-header__settings {
        order: initial; } }

.edit-post-header {
  /* Set left position when auto-fold is not on the body element. */
  left: 0; }
  @media (min-width: 782px) {
    .edit-post-header {
      left: 160px; } }

.auto-fold .edit-post-header {
  /* Auto fold is when on smaller breakpoints, nav menu auto collapses. */ }
  @media (min-width: 782px) {
    .auto-fold .edit-post-header {
      left: 36px; } }
  @media (min-width: 960px) {
    .auto-fold .edit-post-header {
      left: 160px; } }

/* Sidebar manually collapsed. */
.folded .edit-post-header {
  left: 0; }
  @media (min-width: 782px) {
    .folded .edit-post-header {
      left: 36px; } }

/* Mobile menu opened. */
@media (max-width: 782px) {
  .auto-fold .wp-responsive-open .edit-post-header {
    left: 190px; } }

/* In small screens with responsive menu expanded there is small white space. */
@media (max-width: 600px) {
  .auto-fold .wp-responsive-open .edit-post-header {
    margin-left: -18px; } }

body.is-fullscreen-mode .edit-post-header {
  left: 0 !important; }

.edit-post-header__toolbar {
  display: flex; }

.edit-post-header__settings {
  display: inline-flex;
  align-items: center;
  flex-wrap: wrap; }

.edit-post-header .components-button.is-toggled {
  color: #fff;
  background: #555d66;
  margin: 1px;
  padding: 7px; }

.edit-post-header .components-button.is-toggled:hover, .edit-post-header .components-button.is-toggled:focus {
  box-shadow: 0 0 0 1px #555d66, inset 0 0 0 1px #fff !important;
  color: #fff !important;
  background: #555d66 !important; }

.edit-post-header .components-button.editor-post-save-draft, .edit-post-header .components-button.editor-post-switch-to-draft, .edit-post-header .components-button.editor-post-preview, .edit-post-header .components-button.editor-post-publish-button, .edit-post-header .components-button.editor-post-publish-panel__toggle {
  margin: 2px;
  height: 33px;
  line-height: 32px;
  font-size: 13px; }

.edit-post-header .components-button.editor-post-save-draft, .edit-post-header .components-button.editor-post-switch-to-draft {
  padding: 0 5px; }
  @media (min-width: 600px) {
    .edit-post-header .components-button.editor-post-save-draft, .edit-post-header .components-button.editor-post-switch-to-draft {
      padding: 0 12px; } }

.edit-post-header .components-button.editor-post-preview, .edit-post-header .components-button.editor-post-publish-button, .edit-post-header .components-button.editor-post-publish-panel__toggle {
  padding: 0 5px 2px; }
  @media (min-width: 600px) {
    .edit-post-header .components-button.editor-post-preview, .edit-post-header .components-button.editor-post-publish-button, .edit-post-header .components-button.editor-post-publish-panel__toggle {
      padding: 0 12px 2px; } }

@media (min-width: 782px) {
  .edit-post-header .components-button.editor-post-preview {
    margin: 0 3px 0 12px; }
  .edit-post-header .components-button.editor-post-publish-button, .edit-post-header .components-button.editor-post-publish-panel__toggle {
    margin: 0 12px 0 3px; } }

.edit-post-fullscreen-mode-close__toolbar {
  display: none; }
  @media (min-width: 782px) {
    .edit-post-fullscreen-mode-close__toolbar {
      display: flex;
      border-top: 0;
      border-bottom: 0;
      border-left: 0;
      margin: -9px 10px -8px -10px;
      padding: 9px 10px; } }

.edit-post-header-toolbar {
  display: inline-flex;
  align-items: center; }
  .edit-post-header-toolbar > .components-button {
    display: none; }
    @media (min-width: 600px) {
      .edit-post-header-toolbar > .components-button {
        display: inline-flex; } }
  .edit-post-header-toolbar .block-editor-block-navigation,
  .edit-post-header-toolbar .table-of-contents {
    display: none; }
    @media (min-width: 600px) {
      .edit-post-header-toolbar .block-editor-block-navigation,
      .edit-post-header-toolbar .table-of-contents {
        display: flex; } }

.edit-post-header-toolbar__block-toolbar {
  position: absolute;
  top: 56px;
  left: 0;
  right: 0;
  background: #fff;
  min-height: 37px;
  border-bottom: 1px solid #e2e4e7; }
  .edit-post-header-toolbar__block-toolbar .block-editor-block-toolbar .components-toolbar {
    border-top: none;
    border-bottom: none; }
  .is-sidebar-opened .edit-post-header-toolbar__block-toolbar {
    display: none; }
  @media (min-width: 782px) {
    .is-sidebar-opened .edit-post-header-toolbar__block-toolbar {
      display: block;
      right: 280px; } }
  @media (min-width: 1080px) {
    .edit-post-header-toolbar__block-toolbar {
      padding-left: 8px;
      position: static;
      left: auto;
      right: auto;
      background: none;
      border-bottom: none;
      min-height: auto; }
      .is-sidebar-opened .edit-post-header-toolbar__block-toolbar {
        right: auto; }
      .edit-post-header-toolbar__block-toolbar .block-editor-block-toolbar {
        margin: -9px 0; }
      .edit-post-header-toolbar__block-toolbar .block-editor-block-toolbar .components-toolbar {
        padding: 10px 4px 9px; } }

.edit-post-more-menu {
  margin-left: -4px; }
  .edit-post-more-menu .components-icon-button {
    width: auto;
    padding: 8px 2px; }
  @media (min-width: 600px) {
    .edit-post-more-menu {
      margin-left: 4px; }
      .edit-post-more-menu .components-icon-button {
        padding: 8px 4px; } }
  .edit-post-more-menu .components-button svg {
    transform: rotate(90deg); }

.edit-post-more-menu__content .components-popover__content {
  min-width: 260px; }
  @media (min-width: 480px) {
    .edit-post-more-menu__content .components-popover__content {
      width: auto;
      max-width: 480px; } }
  .edit-post-more-menu__content .components-popover__content .components-dropdown-menu__menu {
    padding: 0; }

.edit-post-pinned-plugins {
  display: none; }
  @media (min-width: 600px) {
    .edit-post-pinned-plugins {
      display: flex; } }
  .edit-post-pinned-plugins .components-icon-button {
    margin-left: 4px; }
    .edit-post-pinned-plugins .components-icon-button.is-toggled {
      margin-left: 5px; }
  .edit-post-pinned-plugins .components-icon-button:not(.is-toggled) svg,
  .edit-post-pinned-plugins .components-icon-button:not(.is-toggled) svg * {
    stroke: #555d66;
    fill: #555d66;
    stroke-width: 0; }
  .edit-post-pinned-plugins .components-icon-button.is-toggled svg,
  .edit-post-pinned-plugins .components-icon-button.is-toggled svg *,
  .edit-post-pinned-plugins .components-icon-button.is-toggled:hover svg,
  .edit-post-pinned-plugins .components-icon-button.is-toggled:hover svg * {
    stroke: #fff !important;
    fill: #fff !important;
    stroke-width: 0; }
  .edit-post-pinned-plugins .components-icon-button:hover svg,
  .edit-post-pinned-plugins .components-icon-button:hover svg * {
    stroke: #191e23 !important;
    fill: #191e23 !important;
    stroke-width: 0; }

.edit-post-keyboard-shortcut-help__section {
  margin: 0 0 2rem 0; }

.edit-post-keyboard-shortcut-help__section-title {
  font-size: 0.9rem;
  font-weight: 600; }

.edit-post-keyboard-shortcut-help__shortcut {
  display: flex;
  align-items: center;
  padding: 0.6rem 0;
  border-top: 1px solid #e2e4e7;
  margin-bottom: 0; }
  .edit-post-keyboard-shortcut-help__shortcut:last-child {
    border-bottom: 1px solid #e2e4e7; }

.edit-post-keyboard-shortcut-help__shortcut-term {
  font-weight: 600;
  margin: 0 0 0 1rem; }

.edit-post-keyboard-shortcut-help__shortcut-description {
  flex: 1;
  margin: 0;
  flex-basis: auto; }

.edit-post-keyboard-shortcut-help__shortcut-key-combination {
  background: none;
  margin: 0;
  padding: 0; }

.edit-post-keyboard-shortcut-help__shortcut-key {
  padding: 0.25rem 0.5rem;
  border-radius: 8%;
  margin: 0 0.2rem 0 0.2rem; }
  .edit-post-keyboard-shortcut-help__shortcut-key:last-child {
    margin: 0 0 0 0.2rem; }

.edit-post-layout,
.edit-post-layout__content {
  height: 100%; }

.edit-post-layout {
  position: relative; }
  @media (min-width: 600px) {
    .edit-post-layout {
      padding-top: 56px; } }

.edit-post-layout__metaboxes:not(:empty) {
  border-top: 1px solid #e2e4e7;
  margin-top: 10px;
  padding: 10px 0 10px;
  clear: both; }
  .edit-post-layout__metaboxes:not(:empty) .edit-post-meta-boxes-area {
    margin: auto 20px; }

.edit-post-layout__content .components-editor-notices__snackbar {
  position: fixed;
  right: 0;
  bottom: 20px;
  padding-left: 16px;
  padding-right: 16px; }

.edit-post-layout__content .components-editor-notices__snackbar {
  /* Set left position when auto-fold is not on the body element. */
  left: 0; }
  @media (min-width: 782px) {
    .edit-post-layout__content .components-editor-notices__snackbar {
      left: 160px; } }

.auto-fold .edit-post-layout__content .components-editor-notices__snackbar {
  /* Auto fold is when on smaller breakpoints, nav menu auto collapses. */ }
  @media (min-width: 782px) {
    .auto-fold .edit-post-layout__content .components-editor-notices__snackbar {
      left: 36px; } }
  @media (min-width: 960px) {
    .auto-fold .edit-post-layout__content .components-editor-notices__snackbar {
      left: 160px; } }

/* Sidebar manually collapsed. */
.folded .edit-post-layout__content .components-editor-notices__snackbar {
  left: 0; }
  @media (min-width: 782px) {
    .folded .edit-post-layout__content .components-editor-notices__snackbar {
      left: 36px; } }

/* Mobile menu opened. */
@media (max-width: 782px) {
  .auto-fold .wp-responsive-open .edit-post-layout__content .components-editor-notices__snackbar {
    left: 190px; } }

/* In small screens with responsive menu expanded there is small white space. */
@media (max-width: 600px) {
  .auto-fold .wp-responsive-open .edit-post-layout__content .components-editor-notices__snackbar {
    margin-left: -18px; } }

body.is-fullscreen-mode .edit-post-layout__content .components-editor-notices__snackbar {
  left: 0 !important; }

.edit-post-layout__content {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  position: relative;
  padding-bottom: 50vh;
  -webkit-overflow-scrolling: touch; }
  @media (min-width: 782px) {
    .edit-post-layout__content {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      top: 88px;
      min-height: calc(100% - 88px);
      height: auto;
      margin-left: 160px; }
      body.auto-fold .edit-post-layout__content {
        margin-left: 36px; } }
    @media (min-width: 782px) and (min-width: 960px) {
      body.auto-fold .edit-post-layout__content {
        margin-left: 160px; } }
  @media (min-width: 782px) {
      body.folded .edit-post-layout__content {
        margin-left: 36px; }
      body.is-fullscreen-mode .edit-post-layout__content {
        margin-left: 0 !important;
        top: 56px; } }
  @media (min-width: 782px) {
    .has-fixed-toolbar .edit-post-layout__content {
      top: 124px; } }
  @media (min-width: 1080px) {
    .has-fixed-toolbar .edit-post-layout__content {
      top: 88px; } }
  @media (min-width: 600px) {
    .edit-post-layout__content {
      padding-bottom: 0; } }
  @media (min-width: 600px) {
    .edit-post-layout__content {
      overflow-y: auto; } }
  @media (min-width: 600px) {
    .edit-post-layout__content {
      overscroll-behavior-y: none; } }
  .edit-post-layout__content .edit-post-visual-editor {
    flex: 1 1 auto; }
    @supports ((position: -webkit-sticky) or (position: sticky)) {
      .edit-post-layout__content .edit-post-visual-editor {
        flex-basis: 100%; } }
  .edit-post-layout__content .edit-post-layout__metaboxes {
    flex-shrink: 0; }

.edit-post-layout .editor-post-publish-panel {
  position: fixed;
  z-index: 100001;
  top: 46px;
  bottom: 0;
  right: 0;
  left: 0;
  overflow: auto; }
  @media (min-width: 782px) {
    .edit-post-layout .editor-post-publish-panel {
      top: 32px;
      left: auto;
      width: 280px;
      border-left: 1px solid #e2e4e7;
      transform: translateX(100%);
      animation: edit-post-post-publish-panel__slide-in-animation 0.1s forwards; } }
  @media (min-width: 782px) and (prefers-reduced-motion: reduce) {
    .edit-post-layout .editor-post-publish-panel {
      animation-duration: 1ms; } }
  @media (min-width: 782px) {
      body.is-fullscreen-mode .edit-post-layout .editor-post-publish-panel {
        top: 0; }
      .is-focusing-regions .edit-post-layout .editor-post-publish-panel {
        transform: translateX(0%); } }

@keyframes edit-post-post-publish-panel__slide-in-animation {
  100% {
    transform: translateX(0%); } }

.edit-post-layout .editor-post-publish-panel__header-publish-button .components-button.is-large {
  height: 33px;
  line-height: 32px; }

.edit-post-layout .editor-post-publish-panel__header-publish-button .editor-post-publish-panel__spacer {
  display: inline-flex;
  flex: 0 1 52px; }

.edit-post-toggle-publish-panel {
  position: fixed;
  top: -9999em;
  bottom: auto;
  left: auto;
  right: 0;
  z-index: 100000;
  padding: 10px 10px 10px 0;
  width: 280px;
  background-color: #fff; }
  .edit-post-toggle-publish-panel:focus {
    top: auto;
    bottom: 0; }
  .edit-post-toggle-publish-panel .edit-post-toggle-publish-panel__button {
    width: auto;
    height: auto;
    display: block;
    font-size: 14px;
    font-weight: 600;
    margin: 0 0 0 auto;
    padding: 15px 23px 14px;
    line-height: normal;
    text-decoration: none;
    outline: none;
    background: #f1f1f1;
    color: #11a0d2; }
  body.admin-color-sunrise .edit-post-toggle-publish-panel .edit-post-toggle-publish-panel__button {
    color: #c8b03c; }
  body.admin-color-ocean .edit-post-toggle-publish-panel .edit-post-toggle-publish-panel__button {
    color: #a89d8a; }
  body.admin-color-midnight .edit-post-toggle-publish-panel .edit-post-toggle-publish-panel__button {
    color: #77a6b9; }
  body.admin-color-ectoplasm .edit-post-toggle-publish-panel .edit-post-toggle-publish-panel__button {
    color: #c77430; }
  body.admin-color-coffee .edit-post-toggle-publish-panel .edit-post-toggle-publish-panel__button {
    color: #9fa47b; }
  body.admin-color-blue .edit-post-toggle-publish-panel .edit-post-toggle-publish-panel__button {
    color: #d9ab59; }
  body.admin-color-light .edit-post-toggle-publish-panel .edit-post-toggle-publish-panel__button {
    color: #c75726; }
    .edit-post-toggle-publish-panel .edit-post-toggle-publish-panel__button:focus {
      position: fixed;
      top: auto;
      right: 10px;
      bottom: 10px;
      left: auto; }

@media (min-width: 600px) {
  .edit-post-manage-blocks-modal {
    height: calc(100% - 56px - 56px); } }

.edit-post-manage-blocks-modal .components-modal__content {
  padding-bottom: 0;
  display: flex;
  flex-direction: column; }

.edit-post-manage-blocks-modal .components-modal__header {
  flex-shrink: 0;
  margin-bottom: 0; }

.edit-post-manage-blocks-modal__content {
  display: flex;
  flex-direction: column;
  flex: 0 1 100%;
  min-height: 0; }

.edit-post-manage-blocks-modal__no-results {
  font-style: italic;
  padding: 24px 0;
  text-align: center; }

.edit-post-manage-blocks-modal__search {
  margin: 16px 0; }
  .edit-post-manage-blocks-modal__search .components-base-control__field {
    margin-bottom: 0; }
  .edit-post-manage-blocks-modal__search .components-base-control__label {
    margin-top: -4px; }
  .edit-post-manage-blocks-modal__search input[type="search"].components-text-control__input {
    padding: 12px;
    border-radius: 4px; }

.edit-post-manage-blocks-modal__category {
  margin: 0 0 2rem 0; }

.edit-post-manage-blocks-modal__category-title {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  padding: 16px 0;
  background-color: #fff; }
  .edit-post-manage-blocks-modal__category-title .components-base-control__field {
    margin-bottom: 0; }
  .edit-post-manage-blocks-modal__category-title .components-checkbox-control__label {
    font-size: 0.9rem;
    font-weight: 600; }

.edit-post-manage-blocks-modal__show-all {
  margin-right: 8px; }

.edit-post-manage-blocks-modal__checklist {
  margin-top: 0; }

.edit-post-manage-blocks-modal__checklist-item {
  margin-bottom: 0;
  padding-left: 16px;
  border-top: 1px solid #e2e4e7; }
  .edit-post-manage-blocks-modal__checklist-item:last-child {
    border-bottom: 1px solid #e2e4e7; }
  .edit-post-manage-blocks-modal__checklist-item .components-base-control__field {
    align-items: center;
    display: flex;
    margin: 0; }
  .components-modal__content .edit-post-manage-blocks-modal__checklist-item input[type="checkbox"] {
    margin: 0 8px; }
  .edit-post-manage-blocks-modal__checklist-item .components-checkbox-control__label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-grow: 1;
    padding: 0.6rem 0 0.6rem 10px; }
  .edit-post-manage-blocks-modal__checklist-item .editor-block-icon {
    margin-right: 10px;
    fill: #555d66; }

.edit-post-manage-blocks-modal__results {
  height: 100%;
  overflow: auto;
  margin-left: -16px;
  margin-right: -16px;
  padding-left: 16px;
  padding-right: 16px;
  border-top: 1px solid #e2e4e7; }

.edit-post-meta-boxes-area {
  position: relative;
  /**
	 * The wordpress default for most meta-box elements is content-box. Some
	 * elements such as textarea and input are set to border-box in forms.css.
	 * These elements therefore specifically set back to border-box here, while
	 * other elements (such as .button) are unaffected by Gutenberg's style
	 * because of their higher specificity.
	 */
  /* Match width and positioning of the meta boxes. Override default styles. */
  /* Override Default meta box stylings */ }
  .edit-post-meta-boxes-area__container,
  .edit-post-meta-boxes-area .inside {
    box-sizing: content-box; }
  .edit-post-meta-boxes-area textarea,
  .edit-post-meta-boxes-area input {
    box-sizing: border-box; }
  .edit-post-meta-boxes-area #poststuff {
    margin: 0 auto;
    padding-top: 0;
    min-width: auto; }
  .edit-post-meta-boxes-area #poststuff h3.hndle,
  .edit-post-meta-boxes-area #poststuff .stuffbox > h3,
  .edit-post-meta-boxes-area #poststuff h2.hndle {
    /* WordPress selectors yolo */
    border-bottom: 1px solid #e2e4e7;
    box-sizing: border-box;
    color: inherit;
    font-weight: 600;
    outline: none;
    padding: 15px;
    position: relative;
    width: 100%; }
  .edit-post-meta-boxes-area .postbox {
    border: 0;
    color: inherit;
    margin-bottom: 0; }
  .edit-post-meta-boxes-area .postbox > .inside {
    border-bottom: 1px solid #e2e4e7;
    color: inherit;
    padding: 0 14px 14px;
    margin: 0; }
  .edit-post-meta-boxes-area .postbox .handlediv {
    height: 44px;
    width: 44px; }
  .edit-post-meta-boxes-area.is-loading::before {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: "";
    background: transparent;
    z-index: 1; }
  .edit-post-meta-boxes-area .components-spinner {
    position: absolute;
    top: 10px;
    right: 20px;
    z-index: 5; }
  .edit-post-meta-boxes-area .is-hidden {
    display: none; }

.edit-post-meta-boxes-area__clear {
  clear: both; }

.edit-post-sidebar {
  position: fixed;
  z-index: 100000;
  top: 0;
  right: 0;
  bottom: 0;
  width: 280px;
  border-left: 1px solid #e2e4e7;
  background: #fff;
  color: #555d66;
  height: 100vh;
  overflow: hidden; }
  @media (min-width: 600px) {
    .edit-post-sidebar {
      top: 102px;
      z-index: 90;
      height: auto;
      overflow: auto;
      -webkit-overflow-scrolling: touch; } }
  @media (min-width: 782px) {
    .edit-post-sidebar {
      top: 88px; }
      body.is-fullscreen-mode .edit-post-sidebar {
        top: 56px; } }
  .edit-post-sidebar > .components-panel {
    border-left: none;
    border-right: none;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    height: auto;
    max-height: calc(100vh - 96px);
    margin-top: -1px;
    margin-bottom: -1px;
    position: relative;
    z-index: -2; }
    @media (min-width: 600px) {
      .edit-post-sidebar > .components-panel {
        overflow: hidden;
        height: auto;
        max-height: none; } }
  .edit-post-sidebar > .components-panel .components-panel__header {
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    height: 50px; }
    @media (min-width: 600px) {
      .edit-post-sidebar > .components-panel .components-panel__header {
        position: inherit;
        top: auto;
        left: auto;
        right: auto; } }
  .edit-post-sidebar p {
    margin-top: 0; }
  .edit-post-sidebar h2,
  .edit-post-sidebar h3 {
    font-size: 13px;
    color: #555d66;
    margin-bottom: 1.5em; }
  .edit-post-sidebar hr {
    border-top: none;
    border-bottom: 1px solid #e2e4e7;
    margin: 1.5em 0; }
  .edit-post-sidebar div.components-toolbar {
    box-shadow: none;
    margin-bottom: 1.5em; }
    .edit-post-sidebar div.components-toolbar:last-child {
      margin-bottom: 0; }
  .edit-post-sidebar p + div.components-toolbar {
    margin-top: -1em; }
  .edit-post-sidebar .block-editor-skip-to-selected-block:focus {
    top: auto;
    right: 10px;
    bottom: 10px;
    left: auto; }

/* Visual and Text editor both */
@media (min-width: 782px) {
  .edit-post-layout.is-sidebar-opened .edit-post-layout__content {
    margin-right: 280px; } }

.edit-post-layout.is-sidebar-opened .edit-post-sidebar,
.edit-post-layout.is-sidebar-opened .edit-post-plugin-sidebar__sidebar-layout {
  /* Sidebar covers screen on mobile */
  width: 100%;
  /* Sidebar sits on the side on larger breakpoints */ }
  @media (min-width: 782px) {
    .edit-post-layout.is-sidebar-opened .edit-post-sidebar,
    .edit-post-layout.is-sidebar-opened .edit-post-plugin-sidebar__sidebar-layout {
      width: 280px; } }

/* Text Editor specific */
.components-panel__header.edit-post-sidebar__header {
  background: #fff;
  padding-right: 8px; }
  .components-panel__header.edit-post-sidebar__header .edit-post-sidebar__title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%; }
  @media (min-width: 782px) {
    .components-panel__header.edit-post-sidebar__header {
      display: none; } }

.components-panel__header.edit-post-sidebar__panel-tabs {
  justify-content: flex-start;
  padding-left: 0;
  padding-right: 4px;
  border-top: 0;
  margin-top: 0; }
  .components-panel__header.edit-post-sidebar__panel-tabs .components-icon-button {
    display: none;
    margin-left: auto; }
    @media (min-width: 782px) {
      .components-panel__header.edit-post-sidebar__panel-tabs .components-icon-button {
        display: flex; } }

.edit-post-sidebar__panel-tab {
  background: transparent;
  border: none;
  box-shadow: none;
  cursor: pointer;
  height: 50px;
  padding: 3px 15px;
  margin-left: 0;
  font-weight: 400;
  outline-offset: -1px;
  transition: box-shadow 0.1s linear; }
  @media (prefers-reduced-motion: reduce) {
    .edit-post-sidebar__panel-tab {
      transition-duration: 0s; } }
  .edit-post-sidebar__panel-tab.is-active {
    box-shadow: inset 0 -3px #007cba;
    font-weight: 600;
    position: relative; }
  body.admin-color-sunrise .edit-post-sidebar__panel-tab.is-active {
    box-shadow: inset 0 -3px #837425; }
  body.admin-color-ocean .edit-post-sidebar__panel-tab.is-active {
    box-shadow: inset 0 -3px #5e7d5e; }
  body.admin-color-midnight .edit-post-sidebar__panel-tab.is-active {
    box-shadow: inset 0 -3px #497b8d; }
  body.admin-color-ectoplasm .edit-post-sidebar__panel-tab.is-active {
    box-shadow: inset 0 -3px #523f6d; }
  body.admin-color-coffee .edit-post-sidebar__panel-tab.is-active {
    box-shadow: inset 0 -3px #59524c; }
  body.admin-color-blue .edit-post-sidebar__panel-tab.is-active {
    box-shadow: inset 0 -3px #417e9B; }
  body.admin-color-light .edit-post-sidebar__panel-tab.is-active {
    box-shadow: inset 0 -3px #007cba; }
    .edit-post-sidebar__panel-tab.is-active::before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 1px;
      right: 0;
      left: 0;
      border-bottom: 3px solid transparent; }
  .edit-post-sidebar__panel-tab:focus {
    color: #191e23;
    outline-offset: -1px;
    outline: 1px dotted #555d66; }

.components-panel__body.is-opened.edit-post-last-revision__panel {
  padding: 0; }

.editor-post-last-revision__title {
  padding: 13px 16px; }

.editor-post-author__select {
  margin: -5px 0;
  width: 100%; }
  @supports ((position: -webkit-sticky) or (position: sticky)) {
    .editor-post-author__select {
      width: auto; } }

.edit-post-post-link__link-post-name {
  font-weight: 600; }

.edit-post-post-link__preview-label {
  margin: 0; }

.edit-post-post-link__link {
  word-wrap: break-word; }

.edit-post-post-schedule {
  width: 100%;
  position: relative; }

.edit-post-post-schedule__label {
  display: none; }

.components-button.edit-post-post-schedule__toggle {
  text-align: right; }

.edit-post-post-schedule__dialog .components-popover__content {
  padding: 10px; }
  @media (min-width: 782px) {
    .edit-post-post-schedule__dialog .components-popover__content {
      width: 270px; } }

.edit-post-post-status .edit-post-post-publish-dropdown__switch-to-draft {
  margin-top: 15px;
  width: 100%;
  text-align: center; }

.edit-post-post-visibility {
  width: 100%; }

.edit-post-post-visibility__dialog .components-popover__content {
  padding: 10px; }
  @media (min-width: 782px) {
    .edit-post-post-visibility__dialog .components-popover__content {
      width: 257px; } }

.edit-post-post-visibility__dialog-legend {
  font-weight: 600; }

.edit-post-post-visibility__choice {
  margin: 10px 0; }

.edit-post-post-visibility__dialog-radio,
.edit-post-post-visibility__dialog-label {
  vertical-align: top; }

.edit-post-post-visibility__dialog-password-input {
  width: calc(100% - 20px);
  margin-left: 20px; }

.edit-post-post-visibility__dialog-info {
  color: #7e8993;
  padding-left: 20px;
  font-style: italic;
  margin: 4px 0 0;
  line-height: 1.4; }

.components-panel__header.edit-post-sidebar__panel-tabs {
  justify-content: flex-start;
  padding-left: 0;
  padding-right: 4px;
  border-top: 0;
  position: -webkit-sticky;
  position: sticky;
  z-index: -1;
  top: 0; }
  .components-panel__header.edit-post-sidebar__panel-tabs ul {
    display: flex; }
  .components-panel__header.edit-post-sidebar__panel-tabs li {
    margin: 0; }

.edit-post-sidebar__panel-tab {
  background: transparent;
  border: none;
  box-shadow: none;
  cursor: pointer;
  padding: 3px 15px;
  margin-left: 0;
  font-weight: 400;
  color: #191e23;
  outline-offset: -1px;
  transition: box-shadow 0.1s linear; }
  @media (prefers-reduced-motion: reduce) {
    .edit-post-sidebar__panel-tab {
      transition-duration: 0s; } }
  .edit-post-sidebar__panel-tab::after {
    content: attr(data-label);
    display: block;
    font-weight: 600;
    height: 0;
    overflow: hidden;
    speak: none;
    visibility: hidden; }
  .edit-post-sidebar__panel-tab.is-active {
    box-shadow: inset 0 -3px #007cba;
    font-weight: 600;
    position: relative; }
  body.admin-color-sunrise .edit-post-sidebar__panel-tab.is-active {
    box-shadow: inset 0 -3px #837425; }
  body.admin-color-ocean .edit-post-sidebar__panel-tab.is-active {
    box-shadow: inset 0 -3px #5e7d5e; }
  body.admin-color-midnight .edit-post-sidebar__panel-tab.is-active {
    box-shadow: inset 0 -3px #497b8d; }
  body.admin-color-ectoplasm .edit-post-sidebar__panel-tab.is-active {
    box-shadow: inset 0 -3px #523f6d; }
  body.admin-color-coffee .edit-post-sidebar__panel-tab.is-active {
    box-shadow: inset 0 -3px #59524c; }
  body.admin-color-blue .edit-post-sidebar__panel-tab.is-active {
    box-shadow: inset 0 -3px #417e9B; }
  body.admin-color-light .edit-post-sidebar__panel-tab.is-active {
    box-shadow: inset 0 -3px #007cba; }
    .edit-post-sidebar__panel-tab.is-active::before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 1px;
      right: 0;
      left: 0;
      border-bottom: 3px solid transparent; }
  .edit-post-sidebar__panel-tab:focus {
    color: #191e23;
    outline-offset: -1px;
    outline: 1px dotted #555d66; }

.edit-post-settings-sidebar__panel-block .components-panel__body {
  border: none;
  border-top: 1px solid #e2e4e7;
  margin: 0 -16px; }
  .edit-post-settings-sidebar__panel-block .components-panel__body .components-base-control {
    margin-bottom: 24px; }
    .edit-post-settings-sidebar__panel-block .components-panel__body .components-base-control:last-child {
      margin-bottom: 8px; }
  .edit-post-settings-sidebar__panel-block .components-panel__body .components-panel__body-toggle {
    color: #191e23; }
  .edit-post-settings-sidebar__panel-block .components-panel__body:first-child {
    margin-top: 16px; }
  .edit-post-settings-sidebar__panel-block .components-panel__body:last-child {
    margin-bottom: -16px; }

/* Text Editor specific */
.components-panel__header.edit-post-sidebar-header__small {
  background: #fff;
  padding-right: 4px; }
  .components-panel__header.edit-post-sidebar-header__small .edit-post-sidebar__title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%; }
  @media (min-width: 782px) {
    .components-panel__header.edit-post-sidebar-header__small {
      display: none; } }

.components-panel__header.edit-post-sidebar-header {
  padding-right: 4px;
  background: #f3f4f5; }
  .components-panel__header.edit-post-sidebar-header .components-icon-button {
    display: none;
    margin-left: auto; }
    .components-panel__header.edit-post-sidebar-header .components-icon-button ~ .components-icon-button {
      margin-left: 0; }
    @media (min-width: 782px) {
      .components-panel__header.edit-post-sidebar-header .components-icon-button {
        display: flex; } }

.edit-post-text-editor__body {
  padding-top: 40px; }
  @media (min-width: 600px) {
    .edit-post-text-editor__body {
      padding-top: 86px; } }
  @media (min-width: 782px) {
    .edit-post-text-editor__body {
      padding-top: 40px; }
      body.is-fullscreen-mode .edit-post-text-editor__body {
        padding-top: 40px; } }

.edit-post-text-editor {
  width: 100%;
  max-width: calc(100% - 32px);
  margin-left: 16px;
  margin-right: 16px;
  padding-top: 44px; }
  @media (min-width: 600px) {
    .edit-post-text-editor {
      max-width: 610px;
      margin-left: auto;
      margin-right: auto; } }
  .edit-post-text-editor .editor-post-title__block textarea {
    border: 1px solid #e2e4e7;
    margin-bottom: 4px;
    padding: 14px; }
    .edit-post-text-editor .editor-post-title__block textarea:focus {
      border: 1px solid #e2e4e7; }
  .edit-post-text-editor .editor-post-title__block:not(.is-focus-mode):not(.has-fixed-toolbar):not(.is-selected) .editor-post-title__input:hover,
  .edit-post-text-editor .editor-post-title__block:not(.is-focus-mode):not(.has-fixed-toolbar).is-selected .editor-post-title__input {
    box-shadow: none;
    border-left-width: 1px; }
  .edit-post-text-editor .editor-post-title__block textarea:hover,
  .edit-post-text-editor .editor-post-title__block.is-selected textarea {
    box-shadow: 0 0 0 1px #e2e4e7; }
  .edit-post-text-editor .editor-post-permalink {
    margin-top: -6px;
    box-shadow: none;
    border: none;
    outline: 1px solid #b5bcc2; }
  @media (min-width: 600px) {
    .edit-post-text-editor .editor-post-title,
    .edit-post-text-editor .editor-post-title__block {
      padding: 0; } }
  .edit-post-text-editor .editor-post-text-editor {
    padding: 14px;
    min-height: 200px;
    line-height: 1.8; }
  .edit-post-text-editor .edit-post-text-editor__toolbar {
    position: absolute;
    top: 8px;
    left: 0;
    right: 0;
    height: 36px;
    line-height: 36px;
    padding: 0 8px 0 16px;
    display: flex; }
    .edit-post-text-editor .edit-post-text-editor__toolbar h2 {
      margin: 0 auto 0 0;
      font-size: 13px;
      color: #555d66; }
    .edit-post-text-editor .edit-post-text-editor__toolbar .components-icon-button svg {
      order: 1; }

.edit-post-visual-editor {
  position: relative;
  padding: 50px 0; }
  .edit-post-visual-editor .components-button {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif; }

.edit-post-visual-editor .block-editor-writing-flow__click-redirect {
  height: 50px;
  width: 100%;
  margin: -4px auto -50px; }

.edit-post-visual-editor .block-editor-block-list__block {
  margin-left: auto;
  margin-right: auto; }
  @media (min-width: 600px) {
    .edit-post-visual-editor .block-editor-block-list__block .block-editor-block-list__block-edit {
      margin-left: -28px;
      margin-right: -28px; }
    .edit-post-visual-editor .block-editor-block-list__block[data-align="wide"] > .block-editor-block-list__block-edit > .block-editor-block-contextual-toolbar,
    .edit-post-visual-editor .block-editor-block-list__block[data-align="full"] > .block-editor-block-list__block-edit > .block-editor-block-contextual-toolbar {
      height: 0;
      width: calc(100% - 1px);
      margin-left: 0;
      margin-right: 0;
      text-align: center;
      float: left; }
      .edit-post-visual-editor .block-editor-block-list__block[data-align="wide"] > .block-editor-block-list__block-edit > .block-editor-block-contextual-toolbar .block-editor-block-toolbar,
      .edit-post-visual-editor .block-editor-block-list__block[data-align="full"] > .block-editor-block-list__block-edit > .block-editor-block-contextual-toolbar .block-editor-block-toolbar {
        max-width: 610px;
        width: 100%;
        position: relative; } }

@media (min-width: 600px) {
  .editor-post-title {
    padding-left: 46px;
    padding-right: 46px; } }

.edit-post-visual-editor .editor-post-title__block {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 32px; }
  .edit-post-visual-editor .editor-post-title__block > div {
    margin-left: 0;
    margin-right: 0; }
  @media (min-width: 600px) {
    .edit-post-visual-editor .editor-post-title__block > div {
      margin-left: -2px;
      margin-right: -2px; } }

.edit-post-visual-editor .block-editor-block-list__layout > .block-editor-block-list__block[data-align="left"]:first-child,
.edit-post-visual-editor .block-editor-block-list__layout > .block-editor-block-list__block[data-align="right"]:first-child {
  margin-top: 34px; }

.edit-post-visual-editor .block-editor-default-block-appender {
  margin-left: auto;
  margin-right: auto;
  position: relative; }
  .edit-post-visual-editor .block-editor-default-block-appender[data-root-client-id=""] .block-editor-default-block-appender__content:hover {
    outline: 1px solid transparent; }

.edit-post-visual-editor .block-editor-block-list__block[data-type="core/paragraph"] p[data-is-placeholder-visible="true"] + p,
.edit-post-visual-editor .block-editor-default-block-appender__content {
  min-height: 28px;
  line-height: 1.8; }

.edit-post-options-modal__section {
  margin: 0 0 2rem 0; }

.edit-post-options-modal__section-title {
  font-size: 0.9rem;
  font-weight: 600; }

.edit-post-options-modal__option {
  border-top: 1px solid #e2e4e7; }
  .edit-post-options-modal__option:last-child {
    border-bottom: 1px solid #e2e4e7; }
  .edit-post-options-modal__option .components-base-control__field {
    align-items: center;
    display: flex;
    margin: 0; }
  .edit-post-options-modal__option.components-base-control + .edit-post-options-modal__option.components-base-control {
    margin-bottom: 0; }
  .edit-post-options-modal__option .components-checkbox-control__label {
    flex-grow: 1;
    padding: 0.6rem 0 0.6rem 10px; }

/**
 * Animations
 */
@keyframes edit-post__loading-fade-animation {
  0% {
    opacity: 0.5; }
  50% {
    opacity: 1; }
  100% {
    opacity: 0.5; } }

@keyframes edit-post__fade-in-animation {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

html.wp-toolbar {
  background: #fff; }

body.block-editor-page {
  background: #fff;
  /* We hide legacy notices in Gutenberg Based Pages, because they were not designed in a way that scaled well.
	   Plugins can use Gutenberg notices if they need to pass on information to the user when they are editing. */ }
  body.block-editor-page #wpcontent {
    padding-left: 0; }
  body.block-editor-page #wpbody-content {
    padding-bottom: 0; }
  body.block-editor-page #wpbody-content > div:not(.block-editor):not(#screen-meta) {
    display: none; }
  body.block-editor-page #wpfooter {
    display: none; }
  body.block-editor-page .a11y-speak-region {
    left: -1px;
    top: -1px; }
  body.block-editor-page ul#adminmenu a.wp-has-current-submenu::after,
  body.block-editor-page ul#adminmenu > li.current > a.current::after {
    border-right-color: #fff; }
  body.block-editor-page .media-frame select.attachment-filters:last-of-type {
    width: auto;
    max-width: 100%; }

.block-editor,
.components-modal__frame {
  box-sizing: border-box; }
  .block-editor *,
  .block-editor *::before,
  .block-editor *::after,
  .components-modal__frame *,
  .components-modal__frame *::before,
  .components-modal__frame *::after {
    box-sizing: inherit; }
  .block-editor select,
  .components-modal__frame select {
    font-size: 13px;
    color: #555d66; }
  .block-editor .input-control,
  .block-editor input[type="text"],
  .block-editor input[type="search"],
  .block-editor input[type="radio"],
  .block-editor input[type="tel"],
  .block-editor input[type="time"],
  .block-editor input[type="url"],
  .block-editor input[type="week"],
  .block-editor input[type="password"],
  .block-editor input[type="checkbox"],
  .block-editor input[type="color"],
  .block-editor input[type="date"],
  .block-editor input[type="datetime"],
  .block-editor input[type="datetime-local"],
  .block-editor input[type="email"],
  .block-editor input[type="month"],
  .block-editor input[type="number"],
  .block-editor select,
  .block-editor textarea,
  .components-modal__frame .input-control,
  .components-modal__frame input[type="text"],
  .components-modal__frame input[type="search"],
  .components-modal__frame input[type="radio"],
  .components-modal__frame input[type="tel"],
  .components-modal__frame input[type="time"],
  .components-modal__frame input[type="url"],
  .components-modal__frame input[type="week"],
  .components-modal__frame input[type="password"],
  .components-modal__frame input[type="checkbox"],
  .components-modal__frame input[type="color"],
  .components-modal__frame input[type="date"],
  .components-modal__frame input[type="datetime"],
  .components-modal__frame input[type="datetime-local"],
  .components-modal__frame input[type="email"],
  .components-modal__frame input[type="month"],
  .components-modal__frame input[type="number"],
  .components-modal__frame select,
  .components-modal__frame textarea {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    padding: 6px 8px;
    box-shadow: 0 0 0 transparent;
    transition: box-shadow 0.1s linear;
    border-radius: 4px;
    border: 1px solid #8d96a0;
    /* Fonts smaller than 16px causes mobile safari to zoom. */
    font-size: 16px; }
    @media (prefers-reduced-motion: reduce) {
      .block-editor .input-control,
      .block-editor input[type="text"],
      .block-editor input[type="search"],
      .block-editor input[type="radio"],
      .block-editor input[type="tel"],
      .block-editor input[type="time"],
      .block-editor input[type="url"],
      .block-editor input[type="week"],
      .block-editor input[type="password"],
      .block-editor input[type="checkbox"],
      .block-editor input[type="color"],
      .block-editor input[type="date"],
      .block-editor input[type="datetime"],
      .block-editor input[type="datetime-local"],
      .block-editor input[type="email"],
      .block-editor input[type="month"],
      .block-editor input[type="number"],
      .block-editor select,
      .block-editor textarea,
      .components-modal__frame .input-control,
      .components-modal__frame input[type="text"],
      .components-modal__frame input[type="search"],
      .components-modal__frame input[type="radio"],
      .components-modal__frame input[type="tel"],
      .components-modal__frame input[type="time"],
      .components-modal__frame input[type="url"],
      .components-modal__frame input[type="week"],
      .components-modal__frame input[type="password"],
      .components-modal__frame input[type="checkbox"],
      .components-modal__frame input[type="color"],
      .components-modal__frame input[type="date"],
      .components-modal__frame input[type="datetime"],
      .components-modal__frame input[type="datetime-local"],
      .components-modal__frame input[type="email"],
      .components-modal__frame input[type="month"],
      .components-modal__frame input[type="number"],
      .components-modal__frame select,
      .components-modal__frame textarea {
        transition-duration: 0s; } }
    @media (min-width: 600px) {
      .block-editor .input-control,
      .block-editor input[type="text"],
      .block-editor input[type="search"],
      .block-editor input[type="radio"],
      .block-editor input[type="tel"],
      .block-editor input[type="time"],
      .block-editor input[type="url"],
      .block-editor input[type="week"],
      .block-editor input[type="password"],
      .block-editor input[type="checkbox"],
      .block-editor input[type="color"],
      .block-editor input[type="date"],
      .block-editor input[type="datetime"],
      .block-editor input[type="datetime-local"],
      .block-editor input[type="email"],
      .block-editor input[type="month"],
      .block-editor input[type="number"],
      .block-editor select,
      .block-editor textarea,
      .components-modal__frame .input-control,
      .components-modal__frame input[type="text"],
      .components-modal__frame input[type="search"],
      .components-modal__frame input[type="radio"],
      .components-modal__frame input[type="tel"],
      .components-modal__frame input[type="time"],
      .components-modal__frame input[type="url"],
      .components-modal__frame input[type="week"],
      .components-modal__frame input[type="password"],
      .components-modal__frame input[type="checkbox"],
      .components-modal__frame input[type="color"],
      .components-modal__frame input[type="date"],
      .components-modal__frame input[type="datetime"],
      .components-modal__frame input[type="datetime-local"],
      .components-modal__frame input[type="email"],
      .components-modal__frame input[type="month"],
      .components-modal__frame input[type="number"],
      .components-modal__frame select,
      .components-modal__frame textarea {
        font-size: 13px; } }
    .block-editor .input-control:focus,
    .block-editor input[type="text"]:focus,
    .block-editor input[type="search"]:focus,
    .block-editor input[type="radio"]:focus,
    .block-editor input[type="tel"]:focus,
    .block-editor input[type="time"]:focus,
    .block-editor input[type="url"]:focus,
    .block-editor input[type="week"]:focus,
    .block-editor input[type="password"]:focus,
    .block-editor input[type="checkbox"]:focus,
    .block-editor input[type="color"]:focus,
    .block-editor input[type="date"]:focus,
    .block-editor input[type="datetime"]:focus,
    .block-editor input[type="datetime-local"]:focus,
    .block-editor input[type="email"]:focus,
    .block-editor input[type="month"]:focus,
    .block-editor input[type="number"]:focus,
    .block-editor select:focus,
    .block-editor textarea:focus,
    .components-modal__frame .input-control:focus,
    .components-modal__frame input[type="text"]:focus,
    .components-modal__frame input[type="search"]:focus,
    .components-modal__frame input[type="radio"]:focus,
    .components-modal__frame input[type="tel"]:focus,
    .components-modal__frame input[type="time"]:focus,
    .components-modal__frame input[type="url"]:focus,
    .components-modal__frame input[type="week"]:focus,
    .components-modal__frame input[type="password"]:focus,
    .components-modal__frame input[type="checkbox"]:focus,
    .components-modal__frame input[type="color"]:focus,
    .components-modal__frame input[type="date"]:focus,
    .components-modal__frame input[type="datetime"]:focus,
    .components-modal__frame input[type="datetime-local"]:focus,
    .components-modal__frame input[type="email"]:focus,
    .components-modal__frame input[type="month"]:focus,
    .components-modal__frame input[type="number"]:focus,
    .components-modal__frame select:focus,
    .components-modal__frame textarea:focus {
      color: #191e23;
      border-color: #007cba;
      box-shadow: 0 0 0 1px #007cba;
      outline: 2px solid transparent;
      outline-offset: -2px; }
  .block-editor input[type="number"],
  .components-modal__frame input[type="number"] {
    padding-left: 4px;
    padding-right: 4px; }
  .block-editor select,
  .components-modal__frame select {
    padding: 2px; }
    .block-editor select:focus,
    .components-modal__frame select:focus {
      border-color: #008dbe;
      outline: 2px solid transparent;
      outline-offset: 0; }
  .block-editor input[type="checkbox"],
  .block-editor input[type="radio"],
  .components-modal__frame input[type="checkbox"],
  .components-modal__frame input[type="radio"] {
    border: 2px solid #6c7781;
    margin-right: 12px;
    transition: none; }
    .block-editor input[type="checkbox"]:focus,
    .block-editor input[type="radio"]:focus,
    .components-modal__frame input[type="checkbox"]:focus,
    .components-modal__frame input[type="radio"]:focus {
      border-color: #6c7781;
      box-shadow: 0 0 0 1px #6c7781; }
    .block-editor input[type="checkbox"]:checked,
    .block-editor input[type="radio"]:checked,
    .components-modal__frame input[type="checkbox"]:checked,
    .components-modal__frame input[type="radio"]:checked {
      background: #11a0d2;
      border-color: #11a0d2; }
    body.admin-color-sunrise .block-editor input[type="checkbox"]:checked, body.admin-color-sunrise .block-editor input[type="radio"]:checked, body.admin-color-sunrise .components-modal__frame input[type="checkbox"]:checked, body.admin-color-sunrise .components-modal__frame input[type="radio"]:checked {
      background: #c8b03c;
      border-color: #c8b03c; }
    body.admin-color-ocean .block-editor input[type="checkbox"]:checked, body.admin-color-ocean .block-editor input[type="radio"]:checked, body.admin-color-ocean .components-modal__frame input[type="checkbox"]:checked, body.admin-color-ocean .components-modal__frame input[type="radio"]:checked {
      background: #a3b9a2;
      border-color: #a3b9a2; }
    body.admin-color-midnight .block-editor input[type="checkbox"]:checked, body.admin-color-midnight .block-editor input[type="radio"]:checked, body.admin-color-midnight .components-modal__frame input[type="checkbox"]:checked, body.admin-color-midnight .components-modal__frame input[type="radio"]:checked {
      background: #77a6b9;
      border-color: #77a6b9; }
    body.admin-color-ectoplasm .block-editor input[type="checkbox"]:checked, body.admin-color-ectoplasm .block-editor input[type="radio"]:checked, body.admin-color-ectoplasm .components-modal__frame input[type="checkbox"]:checked, body.admin-color-ectoplasm .components-modal__frame input[type="radio"]:checked {
      background: #a7b656;
      border-color: #a7b656; }
    body.admin-color-coffee .block-editor input[type="checkbox"]:checked, body.admin-color-coffee .block-editor input[type="radio"]:checked, body.admin-color-coffee .components-modal__frame input[type="checkbox"]:checked, body.admin-color-coffee .components-modal__frame input[type="radio"]:checked {
      background: #c2a68c;
      border-color: #c2a68c; }
    body.admin-color-blue .block-editor input[type="checkbox"]:checked, body.admin-color-blue .block-editor input[type="radio"]:checked, body.admin-color-blue .components-modal__frame input[type="checkbox"]:checked, body.admin-color-blue .components-modal__frame input[type="radio"]:checked {
      background: #82b4cb;
      border-color: #82b4cb; }
    body.admin-color-light .block-editor input[type="checkbox"]:checked, body.admin-color-light .block-editor input[type="radio"]:checked, body.admin-color-light .components-modal__frame input[type="checkbox"]:checked, body.admin-color-light .components-modal__frame input[type="radio"]:checked {
      background: #11a0d2;
      border-color: #11a0d2; }
    .block-editor input[type="checkbox"]:checked:focus,
    .block-editor input[type="radio"]:checked:focus,
    .components-modal__frame input[type="checkbox"]:checked:focus,
    .components-modal__frame input[type="radio"]:checked:focus {
      box-shadow: 0 0 0 2px #555d66; }
  .block-editor input[type="checkbox"],
  .components-modal__frame input[type="checkbox"] {
    border-radius: 2px; }
    .block-editor input[type="checkbox"]:checked::before, .block-editor input[type="checkbox"][aria-checked="mixed"]::before,
    .components-modal__frame input[type="checkbox"]:checked::before,
    .components-modal__frame input[type="checkbox"][aria-checked="mixed"]::before {
      margin: -3px -5px;
      color: #fff; }
      @media (min-width: 782px) {
        .block-editor input[type="checkbox"]:checked::before, .block-editor input[type="checkbox"][aria-checked="mixed"]::before,
        .components-modal__frame input[type="checkbox"]:checked::before,
        .components-modal__frame input[type="checkbox"][aria-checked="mixed"]::before {
          margin: -4px 0 0 -5px; } }
    .block-editor input[type="checkbox"][aria-checked="mixed"],
    .components-modal__frame input[type="checkbox"][aria-checked="mixed"] {
      background: #11a0d2;
      border-color: #11a0d2; }
    body.admin-color-sunrise .block-editor input[type="checkbox"][aria-checked="mixed"], body.admin-color-sunrise .components-modal__frame input[type="checkbox"][aria-checked="mixed"] {
      background: #c8b03c;
      border-color: #c8b03c; }
    body.admin-color-ocean .block-editor input[type="checkbox"][aria-checked="mixed"], body.admin-color-ocean .components-modal__frame input[type="checkbox"][aria-checked="mixed"] {
      background: #a3b9a2;
      border-color: #a3b9a2; }
    body.admin-color-midnight .block-editor input[type="checkbox"][aria-checked="mixed"], body.admin-color-midnight .components-modal__frame input[type="checkbox"][aria-checked="mixed"] {
      background: #77a6b9;
      border-color: #77a6b9; }
    body.admin-color-ectoplasm .block-editor input[type="checkbox"][aria-checked="mixed"], body.admin-color-ectoplasm .components-modal__frame input[type="checkbox"][aria-checked="mixed"] {
      background: #a7b656;
      border-color: #a7b656; }
    body.admin-color-coffee .block-editor input[type="checkbox"][aria-checked="mixed"], body.admin-color-coffee .components-modal__frame input[type="checkbox"][aria-checked="mixed"] {
      background: #c2a68c;
      border-color: #c2a68c; }
    body.admin-color-blue .block-editor input[type="checkbox"][aria-checked="mixed"], body.admin-color-blue .components-modal__frame input[type="checkbox"][aria-checked="mixed"] {
      background: #82b4cb;
      border-color: #82b4cb; }
    body.admin-color-light .block-editor input[type="checkbox"][aria-checked="mixed"], body.admin-color-light .components-modal__frame input[type="checkbox"][aria-checked="mixed"] {
      background: #11a0d2;
      border-color: #11a0d2; }
      .block-editor input[type="checkbox"][aria-checked="mixed"]::before,
      .components-modal__frame input[type="checkbox"][aria-checked="mixed"]::before {
        content: "\f460";
        float: left;
        display: inline-block;
        vertical-align: middle;
        width: 16px;
        /* stylelint-disable */
        font: normal 30px/1 dashicons;
        /* stylelint-enable */
        speak: none;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; }
        @media (min-width: 782px) {
          .block-editor input[type="checkbox"][aria-checked="mixed"]::before,
          .components-modal__frame input[type="checkbox"][aria-checked="mixed"]::before {
            float: none;
            font-size: 21px; } }
      .block-editor input[type="checkbox"][aria-checked="mixed"]:focus,
      .components-modal__frame input[type="checkbox"][aria-checked="mixed"]:focus {
        box-shadow: 0 0 0 2px #555d66; }
  .block-editor input[type="radio"],
  .components-modal__frame input[type="radio"] {
    border-radius: 50%; }
    .block-editor input[type="radio"]:checked::before,
    .components-modal__frame input[type="radio"]:checked::before {
      margin: 6px 0 0 6px;
      background-color: #fff; }
      @media (min-width: 782px) {
        .block-editor input[type="radio"]:checked::before,
        .components-modal__frame input[type="radio"]:checked::before {
          margin: 3px 0 0 3px; } }
  .block-editor input::-webkit-input-placeholder,
  .block-editor textarea::-webkit-input-placeholder,
  .components-modal__frame input::-webkit-input-placeholder,
  .components-modal__frame textarea::-webkit-input-placeholder {
    color: rgba(14, 28, 46, 0.62); }
  .block-editor input::-moz-placeholder,
  .block-editor textarea::-moz-placeholder,
  .components-modal__frame input::-moz-placeholder,
  .components-modal__frame textarea::-moz-placeholder {
    opacity: 1;
    color: rgba(14, 28, 46, 0.62); }
  .block-editor input:-ms-input-placeholder,
  .block-editor textarea:-ms-input-placeholder,
  .components-modal__frame input:-ms-input-placeholder,
  .components-modal__frame textarea:-ms-input-placeholder {
    color: rgba(14, 28, 46, 0.62); }
  .is-dark-theme .block-editor input::-webkit-input-placeholder, .is-dark-theme
  .block-editor textarea::-webkit-input-placeholder, .is-dark-theme
  .components-modal__frame input::-webkit-input-placeholder, .is-dark-theme
  .components-modal__frame textarea::-webkit-input-placeholder {
    color: rgba(255, 255, 255, 0.65); }
  .is-dark-theme .block-editor input::-moz-placeholder, .is-dark-theme
  .block-editor textarea::-moz-placeholder, .is-dark-theme
  .components-modal__frame input::-moz-placeholder, .is-dark-theme
  .components-modal__frame textarea::-moz-placeholder {
    opacity: 1;
    color: rgba(255, 255, 255, 0.65); }
  .is-dark-theme .block-editor input:-ms-input-placeholder, .is-dark-theme
  .block-editor textarea:-ms-input-placeholder, .is-dark-theme
  .components-modal__frame input:-ms-input-placeholder, .is-dark-theme
  .components-modal__frame textarea:-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.65); }

@media (min-width: 600px) {
  .block-editor__container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    min-height: calc(100vh - 46px); } }

@media (min-width: 782px) {
  .block-editor__container {
    min-height: calc(100vh - 32px); }
    body.is-fullscreen-mode .block-editor__container {
      min-height: 100vh; } }

.block-editor__container .components-navigate-regions {
  height: 100%; }

.wp-block {
  max-width: 610px; }
  .wp-block[data-align="wide"] {
    max-width: 1100px; }
  .wp-block[data-align="full"] {
    max-width: none; }
