.gutenberg__editor .block-editor-block-types-list__list-item {
  width: 30%; }

.gutenberg__editor .components-drop-zone__provider,
.gutenberg__editor .components-navigate-regions {
  height: auto;
  position: static; }

.gutenberg__editor .edit-post-layout__content {
  position: static;
  margin-left: 0;
  min-height: calc(250px - 56px);
  height: auto;
  overflow-y: auto; }

.gutenberg__editor .edit-post-layout {
  height: auto;
  position: relative;
  top: 0;
  left: 0; }

.gutenberg__editor .edit-post-pinned-plugins {
  display: -webkit-box;
  display: flex; }

.gutenberg__editor .edit-post-visual-editor {
  padding-top: 25px;
  padding-bottom: 75px;
  height: auto; }

@media (min-width: 600px) {
  .gutenberg__editor .edit-post-header {
    position: static;
    top: 0; }
  .gutenberg__editor .edit-post-layout {
    padding-top: 0; } }

.gutenberg__editor.fixed-height .components-drop-zone__provider,
.gutenberg__editor.fixed-height .components-navigate-regions {
  height: 100%; }

.gutenberg__editor.fixed-height .edit-post-layout__content {
  height: calc(100% - 56px); }

.gutenberg__editor.fixed-height .edit-post-layout {
  height: 100%; }

.gutenberg__editor.fixed-height .editor-writing-flow {
  height: auto; }

.gutenberg__content .wp-block-columns {
  display: -webkit-box;
  display: flex; }

.laraberg__editor {
  width: auto;
  overflow: hidden;
  border: 1px solid #e2e4e7;
  box-sizing: border-box;
  min-height: 400px;
  height: auto;
  position: relative;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0; }
  .laraberg__editor *:focus {
    outline: none; }
  .laraberg__editor .components-notice-list {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 100; }
  .laraberg__editor .edit-post-header {
    z-index: 100;
    box-sizing: border-box;
    position: -webkit-sticky;
    position: sticky; }
  .laraberg__editor .edit-post-sidebar {
    position: absolute;
    top: 56px;
    min-height: calc(400px - 56px); }
    .laraberg__editor .edit-post-sidebar .edit-post-sidebar__panel-tabs ul {
      list-style: none;
      margin: 0;
      padding: 0; }
  .laraberg__editor .editor-media-placeholder__media-library-button {
    margin-bottom: 0.5rem; }
  .laraberg__editor .components-placeholder {
    width: auto; }
  .laraberg__editor .screen-reader-text,
  .laraberg__editor .screen-reader-text span,
  .laraberg__editor .ui-helper-hidden-accessible {
    border: 0;
    clip: rect(1px, 1px, 1px, 1px);
    -webkit-clip-path: inset(50%);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    word-wrap: normal !important; }
  .laraberg__editor input[type="checkbox"],
  .laraberg__editor input[type="color"],
  .laraberg__editor input[type="date"],
  .laraberg__editor input[type="datetime-local"],
  .laraberg__editor input[type="datetime"],
  .laraberg__editor input[type="email"],
  .laraberg__editor input[type="month"],
  .laraberg__editor input[type="number"],
  .laraberg__editor input[type="password"],
  .laraberg__editor input[type="radio"],
  .laraberg__editor input[type="search"],
  .laraberg__editor input[type="tel"],
  .laraberg__editor input[type="text"],
  .laraberg__editor input[type="time"],
  .laraberg__editor input[type="url"],
  .laraberg__editor input[type="week"],
  .laraberg__editor select,
  .laraberg__editor textarea {
    border: 1px solid #ddd;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.07);
    background-color: #fff;
    color: #32373c;
    outline: 0;
    -webkit-transition: 50ms border-color ease-in-out;
    transition: 50ms border-color ease-in-out; }
  .laraberg__editor .editor-inserter__manage-reusable-blocks {
    display: none; }
  .laraberg__editor iframe {
    border: 0;
    max-width: 100%; }
  .laraberg__editor .laraberg-sidebar-content {
    padding: 15px;
    margin: unset; }

.gutenberg__content .alignleft {
  float: left;
  margin-right: 1rem; }

.gutenberg__content .alignright {
  float: right;
  margin-left: 1rem; }

.gutenberg__content h1,
.gutenberg__content h2,
.gutenberg__content h3,
.gutenberg__content h4,
.gutenberg__content h5,
.gutenberg__content h6 {
  margin-bottom: 24px; }

.wp-embed-responsive .wp-block-embed.wp-embed-aspect-1-1 .wp-block-embed__wrapper iframe,
.wp-embed-responsive .wp-block-embed.wp-embed-aspect-1-2 .wp-block-embed__wrapper iframe,
.wp-embed-responsive .wp-block-embed.wp-embed-aspect-16-9 .wp-block-embed__wrapper iframe,
.wp-embed-responsive .wp-block-embed.wp-embed-aspect-18-9 .wp-block-embed__wrapper iframe,
.wp-embed-responsive .wp-block-embed.wp-embed-aspect-21-9 .wp-block-embed__wrapper iframe,
.wp-embed-responsive .wp-block-embed.wp-embed-aspect-4-3 .wp-block-embed__wrapper iframe,
.wp-embed-responsive .wp-block-embed.wp-embed-aspect-9-16 .wp-block-embed__wrapper iframe {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.wp-embed-responsive .wp-block-embed.wp-embed-aspect-1-1 .wp-block-embed__wrapper::before,
.wp-embed-responsive .wp-block-embed.wp-embed-aspect-1-2 .wp-block-embed__wrapper::before,
.wp-embed-responsive .wp-block-embed.wp-embed-aspect-16-9 .wp-block-embed__wrapper::before,
.wp-embed-responsive .wp-block-embed.wp-embed-aspect-18-9 .wp-block-embed__wrapper::before,
.wp-embed-responsive .wp-block-embed.wp-embed-aspect-21-9 .wp-block-embed__wrapper::before,
.wp-embed-responsive .wp-block-embed.wp-embed-aspect-4-3 .wp-block-embed__wrapper::before,
.wp-embed-responsive .wp-block-embed.wp-embed-aspect-9-16 .wp-block-embed__wrapper::before {
  content: "";
  display: block;
  padding-top: 50%; }

.laraberg-sidebar {
  display: none; }
