/**
 * Colors
 */
/**
 * Breakpoints & Media Queries
 */
/**
 * Often re-used variables
 */
/**
 * Breakpoint mixins
 */
/**
 * Long content fade mixin
 *
 * Creates a fading overlay to signify that the content is longer
 * than the space allows.
 */
/**
 * Button states and focus styles
 */
/**
 * Applies editor left position to the selector passed as argument
 */
/**
 * Applies editor right position to the selector passed as argument
 */
/**
 * Styles that are reused verbatim in a few places
 */
/**
 * Allows users to opt-out of animations via OS-level preferences.
 */
/**
 * Reset default styles for JavaScript UI based pages.
 * This is a WP-admin agnostic reset
 */
/**
 * Reset the WP Admin page styles for Gutenberg-like pages.
 */
.wp-block-audio audio {
  width: 100%;
  min-width: 300px; }

.wp-block-button {
  color: #fff; }
  .wp-block-button.aligncenter {
    text-align: center; }
  .wp-block-button.alignright {
    /*rtl:ignore*/
    text-align: right; }

.wp-block-button__link {
  background-color: #32373c;
  border: none;
  border-radius: 28px;
  box-shadow: none;
  color: inherit;
  cursor: pointer;
  display: inline-block;
  font-size: 18px;
  margin: 0;
  padding: 12px 24px;
  text-align: center;
  text-decoration: none;
  overflow-wrap: break-word; }
  .wp-block-button__link:hover, .wp-block-button__link:focus, .wp-block-button__link:active, .wp-block-button__link:visited {
    color: inherit; }

.is-style-squared .wp-block-button__link {
  border-radius: 0; }

.is-style-outline {
  color: #32373c; }
  .is-style-outline .wp-block-button__link {
    background-color: transparent;
    border: 2px solid; }

.wp-block-calendar {
  text-align: center; }
  .wp-block-calendar th,
  .wp-block-calendar tbody td {
    padding: 4px;
    border: 1px solid #e2e4e7; }
  .wp-block-calendar tfoot td {
    border: none; }
  .wp-block-calendar table {
    width: 100%;
    border-collapse: collapse;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif; }
  .wp-block-calendar table th {
    font-weight: 440;
    background: #edeff0; }
  .wp-block-calendar a {
    text-decoration: underline; }
  .wp-block-calendar tfoot a {
    color: #00739c; }
  .wp-block-calendar table tbody,
  .wp-block-calendar table caption {
    color: #40464d; }

.wp-block-categories.alignleft {
  /*rtl:ignore*/
  margin-right: 2em; }

.wp-block-categories.alignright {
  /*rtl:ignore*/
  margin-left: 2em; }

.wp-block-columns {
  display: flex;
  margin-bottom: 28px;
  flex-wrap: wrap; }
  @media (min-width: 782px) {
    .wp-block-columns {
      flex-wrap: nowrap; } }

.wp-block-column {
  margin-bottom: 1em;
  flex-grow: 1;
  min-width: 0;
  word-break: break-word;
  overflow-wrap: break-word; }
  @media (max-width: 599px) {
    .wp-block-column {
      flex-basis: 100% !important; } }
  @media (min-width: 600px) {
    .wp-block-column {
      flex-basis: calc(50% - 16px);
      flex-grow: 0; }
      .wp-block-column:nth-child(even) {
        margin-left: 32px; } }
  @media (min-width: 782px) {
    .wp-block-column:not(:first-child) {
      margin-left: 32px; } }

/**
 * All Columns Alignment
 */
.wp-block-columns.are-vertically-aligned-top {
  align-items: flex-start; }

.wp-block-columns.are-vertically-aligned-center {
  align-items: center; }

.wp-block-columns.are-vertically-aligned-bottom {
  align-items: flex-end; }

/**
 * Individual Column Alignment
 */
.wp-block-column.is-vertically-aligned-top {
  align-self: flex-start; }

.wp-block-column.is-vertically-aligned-center {
  align-self: center; }

.wp-block-column.is-vertically-aligned-bottom {
  align-self: flex-end; }

.wp-block-cover-image,
.wp-block-cover {
  position: relative;
  background-color: #000;
  background-size: cover;
  background-position: center center;
  min-height: 430px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden; }
  .wp-block-cover-image.has-left-content,
  .wp-block-cover.has-left-content {
    justify-content: flex-start; }
    .wp-block-cover-image.has-left-content h2,
    .wp-block-cover-image.has-left-content .wp-block-cover-image-text,
    .wp-block-cover-image.has-left-content .wp-block-cover-text,
    .wp-block-cover.has-left-content h2,
    .wp-block-cover.has-left-content .wp-block-cover-image-text,
    .wp-block-cover.has-left-content .wp-block-cover-text {
      margin-left: 0;
      text-align: left; }
  .wp-block-cover-image.has-right-content,
  .wp-block-cover.has-right-content {
    justify-content: flex-end; }
    .wp-block-cover-image.has-right-content h2,
    .wp-block-cover-image.has-right-content .wp-block-cover-image-text,
    .wp-block-cover-image.has-right-content .wp-block-cover-text,
    .wp-block-cover.has-right-content h2,
    .wp-block-cover.has-right-content .wp-block-cover-image-text,
    .wp-block-cover.has-right-content .wp-block-cover-text {
      margin-right: 0;
      text-align: right; }
  .wp-block-cover-image h2,
  .wp-block-cover-image .wp-block-cover-image-text,
  .wp-block-cover-image .wp-block-cover-text,
  .wp-block-cover h2,
  .wp-block-cover .wp-block-cover-image-text,
  .wp-block-cover .wp-block-cover-text {
    color: #fff;
    font-size: 2em;
    line-height: 1.25;
    z-index: 1;
    margin-bottom: 0;
    max-width: 610px;
    padding: 14px;
    text-align: center; }
    .wp-block-cover-image h2 a,
    .wp-block-cover-image h2 a:hover,
    .wp-block-cover-image h2 a:focus,
    .wp-block-cover-image h2 a:active,
    .wp-block-cover-image .wp-block-cover-image-text a,
    .wp-block-cover-image .wp-block-cover-image-text a:hover,
    .wp-block-cover-image .wp-block-cover-image-text a:focus,
    .wp-block-cover-image .wp-block-cover-image-text a:active,
    .wp-block-cover-image .wp-block-cover-text a,
    .wp-block-cover-image .wp-block-cover-text a:hover,
    .wp-block-cover-image .wp-block-cover-text a:focus,
    .wp-block-cover-image .wp-block-cover-text a:active,
    .wp-block-cover h2 a,
    .wp-block-cover h2 a:hover,
    .wp-block-cover h2 a:focus,
    .wp-block-cover h2 a:active,
    .wp-block-cover .wp-block-cover-image-text a,
    .wp-block-cover .wp-block-cover-image-text a:hover,
    .wp-block-cover .wp-block-cover-image-text a:focus,
    .wp-block-cover .wp-block-cover-image-text a:active,
    .wp-block-cover .wp-block-cover-text a,
    .wp-block-cover .wp-block-cover-text a:hover,
    .wp-block-cover .wp-block-cover-text a:focus,
    .wp-block-cover .wp-block-cover-text a:active {
      color: #fff; }
  .wp-block-cover-image.has-parallax,
  .wp-block-cover.has-parallax {
    background-attachment: fixed; }
    @supports (-webkit-overflow-scrolling: touch) {
      .wp-block-cover-image.has-parallax,
      .wp-block-cover.has-parallax {
        background-attachment: scroll; } }
    @media (prefers-reduced-motion: reduce) {
      .wp-block-cover-image.has-parallax,
      .wp-block-cover.has-parallax {
        background-attachment: scroll; } }
  .wp-block-cover-image.has-background-dim::before,
  .wp-block-cover.has-background-dim::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: inherit;
    opacity: 0.5;
    z-index: 1; }
  .wp-block-cover-image.has-background-dim.has-background-dim-10::before,
  .wp-block-cover.has-background-dim.has-background-dim-10::before {
    opacity: 0.1; }
  .wp-block-cover-image.has-background-dim.has-background-dim-20::before,
  .wp-block-cover.has-background-dim.has-background-dim-20::before {
    opacity: 0.2; }
  .wp-block-cover-image.has-background-dim.has-background-dim-30::before,
  .wp-block-cover.has-background-dim.has-background-dim-30::before {
    opacity: 0.3; }
  .wp-block-cover-image.has-background-dim.has-background-dim-40::before,
  .wp-block-cover.has-background-dim.has-background-dim-40::before {
    opacity: 0.4; }
  .wp-block-cover-image.has-background-dim.has-background-dim-50::before,
  .wp-block-cover.has-background-dim.has-background-dim-50::before {
    opacity: 0.5; }
  .wp-block-cover-image.has-background-dim.has-background-dim-60::before,
  .wp-block-cover.has-background-dim.has-background-dim-60::before {
    opacity: 0.6; }
  .wp-block-cover-image.has-background-dim.has-background-dim-70::before,
  .wp-block-cover.has-background-dim.has-background-dim-70::before {
    opacity: 0.7; }
  .wp-block-cover-image.has-background-dim.has-background-dim-80::before,
  .wp-block-cover.has-background-dim.has-background-dim-80::before {
    opacity: 0.8; }
  .wp-block-cover-image.has-background-dim.has-background-dim-90::before,
  .wp-block-cover.has-background-dim.has-background-dim-90::before {
    opacity: 0.9; }
  .wp-block-cover-image.has-background-dim.has-background-dim-100::before,
  .wp-block-cover.has-background-dim.has-background-dim-100::before {
    opacity: 1; }
  .wp-block-cover-image.alignleft, .wp-block-cover-image.alignright,
  .wp-block-cover.alignleft,
  .wp-block-cover.alignright {
    max-width: 305px;
    width: 100%; }
  .wp-block-cover-image::after,
  .wp-block-cover::after {
    display: block;
    content: "";
    font-size: 0;
    min-height: inherit; }
    @supports ((position: -webkit-sticky) or (position: sticky)) {
      .wp-block-cover-image::after,
      .wp-block-cover::after {
        content: none; } }
  .wp-block-cover-image.aligncenter, .wp-block-cover-image.alignleft, .wp-block-cover-image.alignright,
  .wp-block-cover.aligncenter,
  .wp-block-cover.alignleft,
  .wp-block-cover.alignright {
    display: flex; }
  .wp-block-cover-image .wp-block-cover__inner-container,
  .wp-block-cover .wp-block-cover__inner-container {
    width: calc(100% - 70px);
    z-index: 1;
    color: #f8f9f9; }
  .wp-block-cover-image p,
  .wp-block-cover-image h1,
  .wp-block-cover-image h2,
  .wp-block-cover-image h3,
  .wp-block-cover-image h4,
  .wp-block-cover-image h5,
  .wp-block-cover-image h6,
  .wp-block-cover-image .wp-block-subhead,
  .wp-block-cover p,
  .wp-block-cover h1,
  .wp-block-cover h2,
  .wp-block-cover h3,
  .wp-block-cover h4,
  .wp-block-cover h5,
  .wp-block-cover h6,
  .wp-block-cover .wp-block-subhead {
    color: inherit; }

.wp-block-cover__video-background {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 100%;
  height: 100%;
  z-index: 0;
  -o-object-fit: cover;
     object-fit: cover; }

.block-editor-block-list__block[data-type="core/embed"][data-align="left"] .block-editor-block-list__block-edit,
.block-editor-block-list__block[data-type="core/embed"][data-align="right"] .block-editor-block-list__block-edit,
.wp-block-embed.alignleft,
.wp-block-embed.alignright {
  max-width: 360px;
  width: 100%; }

.wp-block-embed {
  margin-bottom: 1em; }

.wp-embed-responsive .wp-block-embed.wp-embed-aspect-21-9 .wp-block-embed__wrapper,
.wp-embed-responsive .wp-block-embed.wp-embed-aspect-18-9 .wp-block-embed__wrapper,
.wp-embed-responsive .wp-block-embed.wp-embed-aspect-16-9 .wp-block-embed__wrapper,
.wp-embed-responsive .wp-block-embed.wp-embed-aspect-4-3 .wp-block-embed__wrapper,
.wp-embed-responsive .wp-block-embed.wp-embed-aspect-1-1 .wp-block-embed__wrapper,
.wp-embed-responsive .wp-block-embed.wp-embed-aspect-9-16 .wp-block-embed__wrapper,
.wp-embed-responsive .wp-block-embed.wp-embed-aspect-1-2 .wp-block-embed__wrapper {
  position: relative; }
  .wp-embed-responsive .wp-block-embed.wp-embed-aspect-21-9 .wp-block-embed__wrapper::before,
  .wp-embed-responsive .wp-block-embed.wp-embed-aspect-18-9 .wp-block-embed__wrapper::before,
  .wp-embed-responsive .wp-block-embed.wp-embed-aspect-16-9 .wp-block-embed__wrapper::before,
  .wp-embed-responsive .wp-block-embed.wp-embed-aspect-4-3 .wp-block-embed__wrapper::before,
  .wp-embed-responsive .wp-block-embed.wp-embed-aspect-1-1 .wp-block-embed__wrapper::before,
  .wp-embed-responsive .wp-block-embed.wp-embed-aspect-9-16 .wp-block-embed__wrapper::before,
  .wp-embed-responsive .wp-block-embed.wp-embed-aspect-1-2 .wp-block-embed__wrapper::before {
    content: "";
    display: block;
    padding-top: 50%; }
  .wp-embed-responsive .wp-block-embed.wp-embed-aspect-21-9 .wp-block-embed__wrapper iframe,
  .wp-embed-responsive .wp-block-embed.wp-embed-aspect-18-9 .wp-block-embed__wrapper iframe,
  .wp-embed-responsive .wp-block-embed.wp-embed-aspect-16-9 .wp-block-embed__wrapper iframe,
  .wp-embed-responsive .wp-block-embed.wp-embed-aspect-4-3 .wp-block-embed__wrapper iframe,
  .wp-embed-responsive .wp-block-embed.wp-embed-aspect-1-1 .wp-block-embed__wrapper iframe,
  .wp-embed-responsive .wp-block-embed.wp-embed-aspect-9-16 .wp-block-embed__wrapper iframe,
  .wp-embed-responsive .wp-block-embed.wp-embed-aspect-1-2 .wp-block-embed__wrapper iframe {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.wp-embed-responsive .wp-block-embed.wp-embed-aspect-21-9 .wp-block-embed__wrapper::before {
  padding-top: 42.85%; }

.wp-embed-responsive .wp-block-embed.wp-embed-aspect-18-9 .wp-block-embed__wrapper::before {
  padding-top: 50%; }

.wp-embed-responsive .wp-block-embed.wp-embed-aspect-16-9 .wp-block-embed__wrapper::before {
  padding-top: 56.25%; }

.wp-embed-responsive .wp-block-embed.wp-embed-aspect-4-3 .wp-block-embed__wrapper::before {
  padding-top: 75%; }

.wp-embed-responsive .wp-block-embed.wp-embed-aspect-1-1 .wp-block-embed__wrapper::before {
  padding-top: 100%; }

.wp-embed-responsive .wp-block-embed.wp-embed-aspect-9-6 .wp-block-embed__wrapper::before {
  padding-top: 66.66%; }

.wp-embed-responsive .wp-block-embed.wp-embed-aspect-1-2 .wp-block-embed__wrapper::before {
  padding-top: 200%; }

.wp-block-file {
  margin-bottom: 1.5em; }
  .wp-block-file.aligncenter {
    text-align: center; }
  .wp-block-file.alignright {
    /*rtl:ignore*/
    text-align: right; }
  .wp-block-file .wp-block-file__button {
    background: #32373c;
    border-radius: 2em;
    color: #fff;
    font-size: 13px;
    padding: 0.5em 1em; }
  .wp-block-file a.wp-block-file__button {
    text-decoration: none; }
    .wp-block-file a.wp-block-file__button:hover, .wp-block-file a.wp-block-file__button:visited, .wp-block-file a.wp-block-file__button:focus, .wp-block-file a.wp-block-file__button:active {
      box-shadow: none;
      color: #fff;
      opacity: 0.85;
      text-decoration: none; }
  .wp-block-file * + .wp-block-file__button {
    margin-left: 0.75em; }

.wp-block-gallery {
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  padding: 0; }
  .wp-block-gallery .blocks-gallery-image,
  .wp-block-gallery .blocks-gallery-item {
    margin: 0 16px 16px 0;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: center;
    position: relative; }
    .wp-block-gallery .blocks-gallery-image figure,
    .wp-block-gallery .blocks-gallery-item figure {
      margin: 0;
      height: 100%; }
      @supports ((position: -webkit-sticky) or (position: sticky)) {
        .wp-block-gallery .blocks-gallery-image figure,
        .wp-block-gallery .blocks-gallery-item figure {
          display: flex;
          align-items: flex-end;
          justify-content: flex-start; } }
    .wp-block-gallery .blocks-gallery-image img,
    .wp-block-gallery .blocks-gallery-item img {
      display: block;
      max-width: 100%;
      height: auto; }
    .wp-block-gallery .blocks-gallery-image img,
    .wp-block-gallery .blocks-gallery-item img {
      width: 100%; }
      @supports ((position: -webkit-sticky) or (position: sticky)) {
        .wp-block-gallery .blocks-gallery-image img,
        .wp-block-gallery .blocks-gallery-item img {
          width: auto; } }
    .wp-block-gallery .blocks-gallery-image figcaption,
    .wp-block-gallery .blocks-gallery-item figcaption {
      position: absolute;
      bottom: 0;
      width: 100%;
      max-height: 100%;
      overflow: auto;
      padding: 40px 10px 9px;
      color: #fff;
      text-align: center;
      font-size: 13px;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.7) 0, rgba(0, 0, 0, 0.3) 70%, transparent); }
      .wp-block-gallery .blocks-gallery-image figcaption img,
      .wp-block-gallery .blocks-gallery-item figcaption img {
        display: inline; }
  .wp-block-gallery.is-cropped .blocks-gallery-image a,
  .wp-block-gallery.is-cropped .blocks-gallery-image img,
  .wp-block-gallery.is-cropped .blocks-gallery-item a,
  .wp-block-gallery.is-cropped .blocks-gallery-item img {
    width: 100%; }
    @supports ((position: -webkit-sticky) or (position: sticky)) {
      .wp-block-gallery.is-cropped .blocks-gallery-image a,
      .wp-block-gallery.is-cropped .blocks-gallery-image img,
      .wp-block-gallery.is-cropped .blocks-gallery-item a,
      .wp-block-gallery.is-cropped .blocks-gallery-item img {
        height: 100%;
        flex: 1;
        -o-object-fit: cover;
           object-fit: cover; } }
  .wp-block-gallery .blocks-gallery-image,
  .wp-block-gallery .blocks-gallery-item {
    width: calc((100% - 16px) / 2); }
    .wp-block-gallery .blocks-gallery-image:nth-of-type(even),
    .wp-block-gallery .blocks-gallery-item:nth-of-type(even) {
      margin-right: 0; }
  .wp-block-gallery.columns-1 .blocks-gallery-image,
  .wp-block-gallery.columns-1 .blocks-gallery-item {
    width: 100%;
    margin-right: 0; }
  @media (min-width: 600px) {
    .wp-block-gallery.columns-3 .blocks-gallery-image,
    .wp-block-gallery.columns-3 .blocks-gallery-item {
      width: calc((100% - 16px * 2) / 3);
      margin-right: 16px; }
      @supports (-ms-ime-align: auto) {
        .wp-block-gallery.columns-3 .blocks-gallery-image,
        .wp-block-gallery.columns-3 .blocks-gallery-item {
          width: calc((100% - 16px * 2) / 3 - 1px); } }
    .wp-block-gallery.columns-4 .blocks-gallery-image,
    .wp-block-gallery.columns-4 .blocks-gallery-item {
      width: calc((100% - 16px * 3) / 4);
      margin-right: 16px; }
      @supports (-ms-ime-align: auto) {
        .wp-block-gallery.columns-4 .blocks-gallery-image,
        .wp-block-gallery.columns-4 .blocks-gallery-item {
          width: calc((100% - 16px * 3) / 4 - 1px); } }
    .wp-block-gallery.columns-5 .blocks-gallery-image,
    .wp-block-gallery.columns-5 .blocks-gallery-item {
      width: calc((100% - 16px * 4) / 5);
      margin-right: 16px; }
      @supports (-ms-ime-align: auto) {
        .wp-block-gallery.columns-5 .blocks-gallery-image,
        .wp-block-gallery.columns-5 .blocks-gallery-item {
          width: calc((100% - 16px * 4) / 5 - 1px); } }
    .wp-block-gallery.columns-6 .blocks-gallery-image,
    .wp-block-gallery.columns-6 .blocks-gallery-item {
      width: calc((100% - 16px * 5) / 6);
      margin-right: 16px; }
      @supports (-ms-ime-align: auto) {
        .wp-block-gallery.columns-6 .blocks-gallery-image,
        .wp-block-gallery.columns-6 .blocks-gallery-item {
          width: calc((100% - 16px * 5) / 6 - 1px); } }
    .wp-block-gallery.columns-7 .blocks-gallery-image,
    .wp-block-gallery.columns-7 .blocks-gallery-item {
      width: calc((100% - 16px * 6) / 7);
      margin-right: 16px; }
      @supports (-ms-ime-align: auto) {
        .wp-block-gallery.columns-7 .blocks-gallery-image,
        .wp-block-gallery.columns-7 .blocks-gallery-item {
          width: calc((100% - 16px * 6) / 7 - 1px); } }
    .wp-block-gallery.columns-8 .blocks-gallery-image,
    .wp-block-gallery.columns-8 .blocks-gallery-item {
      width: calc((100% - 16px * 7) / 8);
      margin-right: 16px; }
      @supports (-ms-ime-align: auto) {
        .wp-block-gallery.columns-8 .blocks-gallery-image,
        .wp-block-gallery.columns-8 .blocks-gallery-item {
          width: calc((100% - 16px * 7) / 8 - 1px); } }
    .wp-block-gallery.columns-1 .blocks-gallery-image:nth-of-type(1n),
    .wp-block-gallery.columns-1 .blocks-gallery-item:nth-of-type(1n) {
      margin-right: 0; }
    .wp-block-gallery.columns-2 .blocks-gallery-image:nth-of-type(2n),
    .wp-block-gallery.columns-2 .blocks-gallery-item:nth-of-type(2n) {
      margin-right: 0; }
    .wp-block-gallery.columns-3 .blocks-gallery-image:nth-of-type(3n),
    .wp-block-gallery.columns-3 .blocks-gallery-item:nth-of-type(3n) {
      margin-right: 0; }
    .wp-block-gallery.columns-4 .blocks-gallery-image:nth-of-type(4n),
    .wp-block-gallery.columns-4 .blocks-gallery-item:nth-of-type(4n) {
      margin-right: 0; }
    .wp-block-gallery.columns-5 .blocks-gallery-image:nth-of-type(5n),
    .wp-block-gallery.columns-5 .blocks-gallery-item:nth-of-type(5n) {
      margin-right: 0; }
    .wp-block-gallery.columns-6 .blocks-gallery-image:nth-of-type(6n),
    .wp-block-gallery.columns-6 .blocks-gallery-item:nth-of-type(6n) {
      margin-right: 0; }
    .wp-block-gallery.columns-7 .blocks-gallery-image:nth-of-type(7n),
    .wp-block-gallery.columns-7 .blocks-gallery-item:nth-of-type(7n) {
      margin-right: 0; }
    .wp-block-gallery.columns-8 .blocks-gallery-image:nth-of-type(8n),
    .wp-block-gallery.columns-8 .blocks-gallery-item:nth-of-type(8n) {
      margin-right: 0; } }
  .wp-block-gallery .blocks-gallery-image:last-child,
  .wp-block-gallery .blocks-gallery-item:last-child {
    margin-right: 0; }
  .wp-block-gallery.alignleft, .wp-block-gallery.alignright {
    max-width: 305px;
    width: 100%; }
  .wp-block-gallery.alignleft, .wp-block-gallery.aligncenter, .wp-block-gallery.alignright {
    display: flex; }
  .wp-block-gallery.aligncenter .blocks-gallery-item figure {
    justify-content: center; }

.wp-block-image {
  max-width: 100%;
  margin-bottom: 1em;
  margin-left: 0;
  margin-right: 0; }
  .wp-block-image img {
    max-width: 100%; }
  .wp-block-image.aligncenter {
    text-align: center; }
  .wp-block-image.alignfull img,
  .wp-block-image.alignwide img {
    width: 100%; }
  .wp-block-image .alignleft,
  .wp-block-image .alignright,
  .wp-block-image .aligncenter, .wp-block-image.is-resized {
    display: table;
    margin-left: 0;
    margin-right: 0; }
    .wp-block-image .alignleft > figcaption,
    .wp-block-image .alignright > figcaption,
    .wp-block-image .aligncenter > figcaption, .wp-block-image.is-resized > figcaption {
      display: table-caption;
      caption-side: bottom; }
  .wp-block-image .alignleft {
    /*rtl:ignore*/
    float: left;
    /*rtl:ignore*/
    margin-right: 1em; }
  .wp-block-image .alignright {
    /*rtl:ignore*/
    float: right;
    /*rtl:ignore*/
    margin-left: 1em; }
  .wp-block-image .aligncenter {
    margin-left: auto;
    margin-right: auto; }

.wp-block-latest-comments__comment {
  font-size: 15px;
  line-height: 1.1;
  list-style: none;
  margin-bottom: 1em; }
  .has-avatars .wp-block-latest-comments__comment {
    min-height: 36px;
    list-style: none; }
    .has-avatars .wp-block-latest-comments__comment .wp-block-latest-comments__comment-meta,
    .has-avatars .wp-block-latest-comments__comment .wp-block-latest-comments__comment-excerpt {
      margin-left: 52px; }
  .has-dates .wp-block-latest-comments__comment,
  .has-excerpts .wp-block-latest-comments__comment {
    line-height: 1.5; }

.wp-block-latest-comments__comment-excerpt p {
  font-size: 14px;
  line-height: 1.8;
  margin: 5px 0 20px; }

.wp-block-latest-comments__comment-date {
  color: #8f98a1;
  display: block;
  font-size: 12px; }

.wp-block-latest-comments .avatar,
.wp-block-latest-comments__comment-avatar {
  border-radius: 24px;
  display: block;
  float: left;
  height: 40px;
  margin-right: 12px;
  width: 40px; }

.wp-block-latest-posts.alignleft {
  /*rtl:ignore*/
  margin-right: 2em; }

.wp-block-latest-posts.alignright {
  /*rtl:ignore*/
  margin-left: 2em; }

.wp-block-latest-posts.wp-block-latest-posts__list {
  list-style: none; }

.wp-block-latest-posts.is-grid {
  display: flex;
  flex-wrap: wrap;
  padding: 0; }
  .wp-block-latest-posts.is-grid li {
    margin: 0 16px 16px 0;
    width: 100%; }

@media (min-width: 600px) {
  .wp-block-latest-posts.columns-2 li {
    width: calc((100% / 2) - 16px); }
  .wp-block-latest-posts.columns-3 li {
    width: calc((100% / 3) - 16px); }
  .wp-block-latest-posts.columns-4 li {
    width: calc((100% / 4) - 16px); }
  .wp-block-latest-posts.columns-5 li {
    width: calc((100% / 5) - 16px); }
  .wp-block-latest-posts.columns-6 li {
    width: calc((100% / 6) - 16px); } }

.wp-block-latest-posts__post-date {
  display: block;
  color: #6c7781;
  font-size: 13px; }

.wp-block-latest-posts__post-excerpt {
  margin-top: 8px;
  margin-bottom: 16px; }

.wp-block-media-text {
  display: grid;
  grid-template-rows: auto;
  align-items: center;
  grid-template-areas: "media-text-media media-text-content";
  grid-template-columns: 50% auto; }
  .wp-block-media-text.has-media-on-the-right {
    grid-template-areas: "media-text-content media-text-media";
    grid-template-columns: auto 50%; }

.wp-block-media-text.is-vertically-aligned-top {
  align-items: start; }

.wp-block-media-text.is-vertically-aligned-center {
  align-items: center; }

.wp-block-media-text.is-vertically-aligned-bottom {
  align-items: end; }

.wp-block-media-text .wp-block-media-text__media {
  grid-area: media-text-media;
  margin: 0; }

.wp-block-media-text .wp-block-media-text__content {
  word-break: break-word;
  grid-area: media-text-content;
  padding: 0 8% 0 8%; }

.wp-block-media-text > figure > img,
.wp-block-media-text > figure > video {
  max-width: unset;
  width: 100%;
  vertical-align: middle; }

.wp-block-media-text.is-image-fill figure {
  height: 100%;
  min-height: 250px;
  background-size: cover; }

.wp-block-media-text.is-image-fill figure > img {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0; }

/*
* Here we here not able to use a mobile first CSS approach.
* Custom widths are set using inline styles, and on mobile,
* we need 100% width, so we use important to overwrite the inline style.
* If the style were set on mobile first, on desktop styles,
* we would have no way of setting the style again to the inline style.
*/
@media (max-width: 600px) {
  .wp-block-media-text.is-stacked-on-mobile {
    grid-template-columns: 100% !important;
    grid-template-areas: "media-text-media" "media-text-content"; }
  .wp-block-media-text.is-stacked-on-mobile.has-media-on-the-right {
    grid-template-areas: "media-text-content" "media-text-media"; } }

.is-small-text {
  font-size: 14px; }

.is-regular-text {
  font-size: 16px; }

.is-large-text {
  font-size: 36px; }

.is-larger-text {
  font-size: 48px; }

.has-drop-cap:not(:focus)::first-letter {
  float: left;
  font-size: 8.4em;
  line-height: 0.68;
  font-weight: 100;
  margin: 0.05em 0.1em 0 0;
  text-transform: uppercase;
  font-style: normal; }

.has-drop-cap:not(:focus)::after {
  content: "";
  display: table;
  clear: both;
  padding-top: 14px; }

p.has-background {
  padding: 20px 30px; }

p.has-text-color a {
  color: inherit; }

.wp-block-pullquote {
  padding: 3em 0;
  margin-left: 0;
  margin-right: 0;
  text-align: center; }
  .wp-block-pullquote.alignleft, .wp-block-pullquote.alignright {
    max-width: 305px; }
    .wp-block-pullquote.alignleft p, .wp-block-pullquote.alignright p {
      font-size: 20px; }
  .wp-block-pullquote p {
    font-size: 28px;
    line-height: 1.6; }
  .wp-block-pullquote cite,
  .wp-block-pullquote footer {
    position: relative; }
  .wp-block-pullquote .has-text-color a {
    color: inherit; }

.wp-block-pullquote:not(.is-style-solid-color) {
  background: none; }

.wp-block-pullquote.is-style-solid-color {
  border: none; }
  .wp-block-pullquote.is-style-solid-color blockquote {
    margin-left: auto;
    margin-right: auto;
    text-align: left;
    max-width: 60%; }
    .wp-block-pullquote.is-style-solid-color blockquote p {
      margin-top: 0;
      margin-bottom: 0;
      font-size: 32px; }
    .wp-block-pullquote.is-style-solid-color blockquote cite {
      text-transform: none;
      font-style: normal; }

.wp-block-pullquote cite {
  color: inherit; }

.wp-block-quote.is-style-large, .wp-block-quote.is-large {
  margin: 0 0 16px;
  padding: 0 1em; }
  .wp-block-quote.is-style-large p, .wp-block-quote.is-large p {
    font-size: 24px;
    font-style: italic;
    line-height: 1.6; }
  .wp-block-quote.is-style-large cite,
  .wp-block-quote.is-style-large footer, .wp-block-quote.is-large cite,
  .wp-block-quote.is-large footer {
    font-size: 18px;
    text-align: right; }

.wp-block-rss.alignleft {
  /*rtl:ignore*/
  margin-right: 2em; }

.wp-block-rss.alignright {
  /*rtl:ignore*/
  margin-left: 2em; }

.wp-block-rss.is-grid {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  list-style: none; }
  .wp-block-rss.is-grid li {
    margin: 0 16px 16px 0;
    width: 100%; }

@media (min-width: 600px) {
  .wp-block-rss.columns-2 li {
    width: calc(( 100% / 2 ) - 16px); }
  .wp-block-rss.columns-3 li {
    width: calc(( 100% / 3 ) - 16px); }
  .wp-block-rss.columns-4 li {
    width: calc(( 100% / 4 ) - 16px); }
  .wp-block-rss.columns-5 li {
    width: calc(( 100% / 5 ) - 16px); }
  .wp-block-rss.columns-6 li {
    width: calc(( 100% / 6 ) - 16px); } }

.wp-block-rss__item-publish-date,
.wp-block-rss__item-author {
  display: block;
  color: #6c7781;
  font-size: 13px; }

.wp-block-search {
  display: flex;
  flex-wrap: wrap; }
  .wp-block-search .wp-block-search__label {
    width: 100%; }
  .wp-block-search .wp-block-search__input {
    flex-grow: 1; }
  .wp-block-search .wp-block-search__button {
    margin-left: 10px; }

.wp-block-separator.is-style-wide {
  border-bottom-width: 1px; }

.wp-block-separator.is-style-dots {
  background: none;
  border: none;
  text-align: center;
  max-width: none;
  line-height: 1;
  height: auto; }
  .wp-block-separator.is-style-dots::before {
    content: "\00b7 \00b7 \00b7";
    color: #191e23;
    font-size: 20px;
    letter-spacing: 2em;
    padding-left: 2em;
    font-family: serif; }

.wp-block-spacer {
  clear: both; }

p.wp-block-subhead {
  font-size: 1.1em;
  font-style: italic;
  opacity: 0.75; }

.wp-block-table.has-fixed-layout {
  table-layout: fixed;
  width: 100%; }

.wp-block-table.alignleft, .wp-block-table.aligncenter, .wp-block-table.alignright {
  display: table;
  width: auto; }

.wp-block-table.has-subtle-light-gray-background-color {
  background-color: #f3f4f5; }

.wp-block-table.has-subtle-pale-green-background-color {
  background-color: #e9fbe5; }

.wp-block-table.has-subtle-pale-blue-background-color {
  background-color: #e7f5fe; }

.wp-block-table.has-subtle-pale-pink-background-color {
  background-color: #fcf0ef; }

.wp-block-table.is-style-stripes {
  border-spacing: 0;
  border-collapse: inherit;
  background-color: transparent;
  border-bottom: 1px solid #f3f4f5; }
  .wp-block-table.is-style-stripes tbody tr:nth-child(odd) {
    background-color: #f3f4f5; }
  .wp-block-table.is-style-stripes.has-subtle-light-gray-background-color tbody tr:nth-child(odd) {
    background-color: #f3f4f5; }
  .wp-block-table.is-style-stripes.has-subtle-pale-green-background-color tbody tr:nth-child(odd) {
    background-color: #e9fbe5; }
  .wp-block-table.is-style-stripes.has-subtle-pale-blue-background-color tbody tr:nth-child(odd) {
    background-color: #e7f5fe; }
  .wp-block-table.is-style-stripes.has-subtle-pale-pink-background-color tbody tr:nth-child(odd) {
    background-color: #fcf0ef; }
  .wp-block-table.is-style-stripes th,
  .wp-block-table.is-style-stripes td {
    border-color: transparent; }

.wp-block-text-columns {
  display: flex; }
  .wp-block-text-columns.aligncenter {
    display: flex; }
  .wp-block-text-columns .wp-block-column {
    margin: 0 16px;
    padding: 0; }
    .wp-block-text-columns .wp-block-column:first-child {
      margin-left: 0; }
    .wp-block-text-columns .wp-block-column:last-child {
      margin-right: 0; }
  .wp-block-text-columns.columns-2 .wp-block-column {
    width: calc(100% / 2); }
  .wp-block-text-columns.columns-3 .wp-block-column {
    width: calc(100% / 3); }
  .wp-block-text-columns.columns-4 .wp-block-column {
    width: calc(100% / 4); }

pre.wp-block-verse {
  white-space: nowrap;
  overflow: auto; }

.wp-block-video {
  margin-left: 0;
  margin-right: 0; }
  .wp-block-video video {
    max-width: 100%; }
  @supports ((position: -webkit-sticky) or (position: sticky)) {
    .wp-block-video [poster] {
      -o-object-fit: cover;
         object-fit: cover; } }
  .wp-block-video.aligncenter {
    text-align: center; }

:root .has-pale-pink-background-color {
  background-color: #f78da7; }

:root .has-vivid-red-background-color {
  background-color: #cf2e2e; }

:root .has-luminous-vivid-orange-background-color {
  background-color: #ff6900; }

:root .has-luminous-vivid-amber-background-color {
  background-color: #fcb900; }

:root .has-light-green-cyan-background-color {
  background-color: #7bdcb5; }

:root .has-vivid-green-cyan-background-color {
  background-color: #00d084; }

:root .has-pale-cyan-blue-background-color {
  background-color: #8ed1fc; }

:root .has-vivid-cyan-blue-background-color {
  background-color: #0693e3; }

:root .has-very-light-gray-background-color {
  background-color: #eee; }

:root .has-cyan-bluish-gray-background-color {
  background-color: #abb8c3; }

:root .has-very-dark-gray-background-color {
  background-color: #313131; }

:root .has-pale-pink-color {
  color: #f78da7; }

:root .has-vivid-red-color {
  color: #cf2e2e; }

:root .has-luminous-vivid-orange-color {
  color: #ff6900; }

:root .has-luminous-vivid-amber-color {
  color: #fcb900; }

:root .has-light-green-cyan-color {
  color: #7bdcb5; }

:root .has-vivid-green-cyan-color {
  color: #00d084; }

:root .has-pale-cyan-blue-color {
  color: #8ed1fc; }

:root .has-vivid-cyan-blue-color {
  color: #0693e3; }

:root .has-very-light-gray-color {
  color: #eee; }

:root .has-cyan-bluish-gray-color {
  color: #abb8c3; }

:root .has-very-dark-gray-color {
  color: #313131; }

.has-small-font-size {
  font-size: 13px; }

.has-regular-font-size,
.has-normal-font-size {
  font-size: 16px; }

.has-medium-font-size {
  font-size: 20px; }

.has-large-font-size {
  font-size: 36px; }

.has-larger-font-size,
.has-huge-font-size {
  font-size: 42px; }

.has-text-align-center {
  text-align: center; }

.has-text-align-left {
  text-align: left; }

.has-text-align-right {
  text-align: right; }

/**
 * Vanilla Block Styles
 * These are base styles that apply across blocks, meant to provide a baseline.
 * They are applied both to the editor and the theme, so we should have as few of these as possible.
 * Please note that some styles are stored in packages/editor/src/editor-styles.scss, as they pertain to CSS bleed for the editor only.
 */
figcaption {
  margin-top: 0.5em; }

img {
  max-width: 100%;
  height: auto; }

iframe {
  width: 100%; }
